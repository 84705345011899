<mat-card class="container-user card-container">
  <mat-card-content>
    <form action="" [formGroup]="userForm" (ngSubmit)="submitForm()">
      <h6 *ngIf="!id" class="bottom mt-1">Cadastro de usuário</h6>
      <h6 *ngIf="id" class="bottom mt-1">Edição de usuário</h6>
      <span class="description">Dados do usuário</span>

      <div fxLayout="row" fxLayoutAlign="space-between">
        <div fxLayout="column" fxFlex>
          <div class="form-group mt-4">
            <label for="" class="label-input form-label required">
              Nome de usuário
            </label>
            <input
              #userRef
              type="text"
              class="input-data form-control"
              placeholder="Digite o nome do usuário"
              required
              name="user"
              formControlName="user"
              [ngClass]="{
                'is-invalid':
                  userForm.get('user')?.invalid &&
                  userForm.get('user')?.touched,
                'is-valid':
                  userForm.get('user')?.valid && userForm.get('user')?.touched
              }"
            />
            <div
              *ngIf="
                (userForm.get('user')?.errors &&
                  userForm.get('user')?.touched) ||
                userForm.get('user')?.dirty
              "
              class="invalid-feedback label-mandatory"
            >
              Campo obrigatório*
            </div>
          </div>
        </div>
      </div>

      <div fxLayout="row" fxLayoutAlign="space-between" class="mt-4 bottom">
        <div fxLayout="column" fxFlex class="column-left">
          <div class="form-group">
            <label for="" class="label-input form-label required">
              E-mail
            </label>
            <input
              #emailRef
              type="email"
              class="input-data form-control"
              placeholder="example@senior.com.br"
              required
              name="email"
              formControlName="email"
              [ngClass]="{
                'is-invalid':
                  userForm.get('email')?.invalid &&
                  userForm.get('email')?.touched,
                'is-valid':
                  checkEmail() &&
                  userForm.get('email')?.valid &&
                  userForm.get('email')?.touched &&
                  userForm.get('email')?.value.length > 0
              }"
            />
            <div
              *ngIf="
                userForm.get('email')?.invalid &&
                userForm.get('email')?.touched &&
                userForm.get('email')?.value.length === 0
              "
              class="invalid-feedback label-mandatory"
            >
              Campo obrigatório*
            </div>
            <div
              *ngIf="
                checkEmail() === false &&
                userForm.get('email')?.value.length > 0 &&
                userForm.get('email')?.errors
              "
              class="invalid-feedback label-mandatory"
            >
              Email inválido!*
            </div>
          </div>
        </div>
        <div fxLayout="column" fxFlex class="column-right">
          <div class="form-group">
            <label for="" class="label-input form-label required">
              Telefone
            </label>
            <input
              #phoneRef
              mask="(00) 0000-0000||(00) 00000-0000"
              class="input-data form-control"
              placeholder="(xx) xxxx-xxxx"
              required
              name="phone"
              formControlName="phone"
              [ngClass]="{
                'is-invalid':
                userForm.get('phone')?.invalid &&
                userForm.get('phone')?.touched,
                'is-valid':
                userForm.get('phone')?.valid &&
                userForm.get('phone')?.touched,
              }"
            />
            <div
              *ngIf="
                userForm.get('phone')?.value.length === 0 &&
                userForm.get('phone')?.touched &&
                userForm.get('phone')?.invalid
              "
              class="invalid-feedback label-mandatory"
            >
              Campo obrigatório*
            </div>
            <div
              *ngIf="
                userForm.get('phone')?.errors &&
                userForm.get('phone')?.value.length > 0 &&
                userForm.get('phone')?.dirty
              "
              class="invalid-feedback label-mandatory"
            >
              Telefone inválido!*
            </div>
          </div>
        </div>
      </div>
      <div fxLayout="row" fxLayoutAlign="space-between" class="mt-2">
        <div fxLayout="column" fxFlex class="column-left">
          <div class="form-group mt-3">
            <label for="" class="label-input form-label required">
              Tipo de usuário
            </label>
            <ng-select
              #userTypeRef
              *ngIf="options.length > 0"
              required
              name="userType"
              formControlName="userType"
              [items]="options"
              [closeOnSelect]="true"
              [searchable]="true"
              [clearable]="false"
              bindLabel="label"
              bindValue="value"
              [ngClass]="{
                'invalid is-invalid':
                userForm.get('userType')?.invalid &&
                userForm.get('userType')?.touched,
                'valid is-valid':
                userForm.get('userType')?.valid &&
                userForm.get('userType')?.touched,
              }"
            >
              <ng-template
                ng-label-tmp
                let-item="item"
                let-index="index"
                let-search="searchTerm"
              >
                {{ item.label }}
              </ng-template>
            </ng-select>
            <div
              *ngIf="
                userForm.get('userType')?.value.length === 0 &&
                userForm.get('userType')?.touched &&
                userForm.get('userType')?.invalid
              "
              class="invalid-feedback label-mandatory"
            >
              Campo obrigatório*
            </div>
          </div>
        </div>
        <div fxLayout="column" fxFlex class="column-right">
          <div class="form-group mt-3">
            <label for="" class="label-input form-label required">
              Alçada
            </label>
            <input
              #alcadaRef
              matInput
              prefix="R$ "
              mask="separator.2"
              thousandSeparator="."
              name="totalValue"
              class="input-data form-control"
              placeholder="Valor máximo que poderá dar um lance na oferta de frete"
              required
              name="alcada"
              formControlName="alcada"
              [ngClass]="{
                'is-invalid':
                userForm.get('alcada')?.invalid &&
                userForm.get('alcada')?.touched,
                'is-valid':
                userForm.get('alcada')?.valid &&
                userForm.get('alcada')?.touched,
              }"
            />
            <div
              *ngIf="
                userForm.get('alcada')?.value?.length === 0 &&
                userForm.get('alcada')?.touched &&
                userForm.get('alcada')?.invalid
              "
              class="invalid-feedback label-mandatory"
            >
              Campo obrigatório*
            </div>
            <div
              *ngIf="
                userForm.get('alcada')?.errors &&
                userForm.get('alcada')?.value?.length > 0 &&
                userForm.get('alcada')?.dirty
              "
              class="invalid-feedback label-mandatory"
            >
            Alcada inválida!*
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="!id" fxLayout="row" fxLayoutAlign="space-between" class="mt-4 bottom">
        <div fxLayout="column" fxFlex class="column-left">
          <div class="form-group">
            <label for="" class="label-input form-label required">
              Senha
            </label>
            <input
              #passwordRef
              [type]="typePassword"
              class="input-data form-control"
              placeholder="Senha"
              required
              name="password"
              formControlName="password"
              [ngClass]="{
                'is-invalid':
                (userForm.get('password')?.invalid &&
                userForm.get('password')?.touched) || userForm.get('password')?.value?.length > 0 && (!validLength || !validUpper || !validLower || !validNumber || !validSpecialCharacter),
                'is-valid':
                userForm.get('password')?.value?.length > 0 && validLength && validUpper && validLower && validNumber && validSpecialCharacter,
              }"
            />
            <div
              *ngIf="
                userForm.get('password')?.value.length === 0 &&
                userForm.get('password')?.touched &&
                userForm.get('password')?.invalid
              "
              class="invalid-feedback label-mandatory"
            >
              Campo obrigatório*
            </div>
          </div>
          <div class="form-check mt-2">
            <input
              class="form-check-input"
              type="checkbox"
              value=""
              id="showPassword"
              (change)="showPassword($event)"
            />
            <label
              class="form-check-label mt-1 view-password"
              for="showPassword"
            >
              Mostrar senha
            </label>
          </div>
        </div>
        <div fxLayout="column" fxFlex class="column-right">
          <div class="form-group">
            <label for="" class="label-input form-label required">
              Repita a senha
            </label>
            <input
              #repitaSenhaRef
              [type]="typeRepitPassword"
              class="input-data form-control"
              placeholder="Repita a senha"
              (change)="validConfirmPassword()"
              required
              name="repitaSenha"
              formControlName="repitaSenha"
              [ngClass]="{
                'is-invalid':
                (userForm.get('repitaSenha')?.invalid &&
                userForm.get('repitaSenha')?.touched) || (userForm.get('repitaSenha')?.value?.length > 0 && !confirmPasswordValue ),
                'is-valid':
                userForm.get('repitaSenha')?.value?.length > 0 && confirmPasswordValue,
              }"
            />
            <div
              *ngIf="
                userForm.get('repitaSenha')?.value.length === 0 &&
                userForm.get('repitaSenha')?.touched &&
                userForm.get('repitaSenha')?.invalid
              "
              class="invalid-feedback label-mandatory"
            >
              Campo obrigatório*
            </div>
            <div
            *ngIf="
              userForm.get('repitaSenha')?.value?.length > 0 &&
              userForm.get('repitaSenha')?.touched &&
              !confirmPasswordValue
            "
            class="label-mandatory"
            style="
              margin-top: 0.25rem !important;
              color: var(--bs-form-invalid-color) !important;
            "
          >
            Confirmação de senha incorreta*
          </div>
          </div>
          <div class="form-check mt-2">
            <input
              class="form-check-input"
              type="checkbox"
              value=""
              id="showRepitPassword"
              (change)="showRepitPassword($event)"
            />
            <label
              class="form-check-label mt-1 view-password"
              for="showRepitPassword"
            >
              Mostrar senha
            </label>
          </div>
        </div>
      </div>

      <div fxLayout="row" fxLayoutAlign="start">
        <div fxLayout="column" fxFlex class="right mt-3 column-left" *ngIf="!id">
          <div class="container-passwordvalidate">
            <p class="text-password">Sua senha deve conter:</p>
              <p>
                <i
                  *ngIf="emptyPassword || (!emptyPassword && validLength)"
                  class="ri-checkbox-circle-fill icon-check"
                  [ngClass]="{
                    'check-none': emptyPassword,
                    'check-yes': !emptyPassword && validLength
                  }"
                ></i
                ><i
                  class="ri-close-circle-fill icon-check check-no"
                  *ngIf="!emptyPassword && !validLength"
                ></i
                >Entre 8 e 30 caracteres
              </p>
              <p>
                <i
                  *ngIf="
                    emptyPassword || (!emptyPassword && validUpper && validLower)
                  "
                  class="ri-checkbox-circle-fill icon-check"
                  [ngClass]="{
                    'check-none': emptyPassword,
                    'check-yes': !emptyPassword && validUpper && validLower
                  }"
                ></i
                ><i
                  class="ri-close-circle-fill icon-check check-no"
                  *ngIf="!emptyPassword && (!validUpper || !validLower)"
                ></i
                >Letras maiúsculas e minúsculas
              </p>
              <p>
                <i
                  *ngIf="emptyPassword || (!emptyPassword && validNumber)"
                  class="ri-checkbox-circle-fill icon-check"
                  [ngClass]="{
                    'check-none': emptyPassword,
                    'check-yes': !emptyPassword && validNumber
                  }"
                ></i
                ><i
                  class="ri-close-circle-fill icon-check check-no"
                  *ngIf="!emptyPassword && !validNumber"
                ></i
                >Pelo menos um número
              </p>
              <p>
                <i
                  *ngIf="emptyPassword || (!emptyPassword && validSpecialCharacter)"
                  class="ri-checkbox-circle-fill icon-check"
                  [ngClass]="{
                    'check-none': emptyPassword,
                    'check-yes': !emptyPassword && validSpecialCharacter
                  }"
                ></i
                ><i
                  class="ri-close-circle-fill icon-check check-no"
                  *ngIf="!emptyPassword && !validSpecialCharacter"
                ></i
                >Pelo menos um caractere especial
              </p>
          </div>
        </div>
        <div fxLayout="column" fxFlex="49.6" [ngClass]="{'column-right': !id}">
          <div class="form-group mt-3">
            <label class="label-input form-label required" for="carriersByUser">
              Selecione os Transportadores para Gestão
            </label>
            <ng-select
              #carriersByUserRef
              name="carriersByUser"
              formControlName="carriersByUser"
              [items]="carriersByUser"
              [multiple]="true"
              [closeOnSelect]="false"
              [searchable]="false"
              required
              bindLabel="label"
              bindValue="value"
              [ngClass]="{
                'invalid is-invalid':
                 userForm.get('carriersByUser')?.invalid &&
                 userForm.get('carriersByUser')?.touched,
                'valid is-valid':
                userForm.get('carriersByUser')?.valid &&
                userForm.get('carriersByUser')?.touched || userForm.get('carriersByUser')?.valid && userForm.get('carriersByUser')?.value.length > 0,

              }"
            ></ng-select>
            <div
              *ngIf="
                (userForm.get('carriersByUser')?.invalid &&
                  userForm.get('carriersByUser')?.value.length == 0) ||
                (userForm.get('carriersByUser')?.dirty &&
                  userForm.get('carriersByUser')?.value.length == 0)
              "
              class="invalid-feedback label-mandatory"
            >
              Campo obrigatório*
            </div>
          </div>
        </div>
      </div>

      <div class="d-flex flex-row-reverse mt-5">
        <div>
          <button
            mat-flat-button
            class="btn-green"
          >
            Salvar
          </button>
        </div>
      </div>
    </form>
  </mat-card-content>
</mat-card>
