import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { SelectionModel } from '@angular/cdk/collections';
import {
  produto_options,
  veiculo_options,
  carga_options,
  uf_options_requisitos,
} from 'src/app/shared/const/consts';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { RequirementsService } from '../requirements/requirements.service';
import { Observable, first } from 'rxjs';
import { SnackbarDefaultService } from '../services/snackbar-default.service';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';

export interface HeaderTable {
  columnA: string;
  columnB: string;
}

@Component({
  selector: 'app-dialog-requirements-register',
  templateUrl: './dialog-requirements-register.component.html',
  styleUrls: ['./dialog-requirements-register.component.scss'],
})
export class DialogRequirementsRegisterComponent implements OnInit {
  type: string = '';
  title: string = '';
  columnA: string = '';
  id: any;
  vehicles: any;
  visibleTable: boolean = false;
  visibleQuantity: boolean = true;

  page: number = 0;
  size: number = 50;
  totalElements: number = 0;
  dados: any[];

  @ViewChild(MatPaginator)
  paginator!: MatPaginator;

  displayedColumns: string[] = ['select', 'description'];

  selection = new SelectionModel<any>(true, []);

  public searchForm: FormGroup = this.formBuilder.group({
    search: [null],
  });

  public quantityForm: FormGroup = this.formBuilder.group({
    quantity: [''],
    veiculos: this.formBuilder.array([])
  });

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder,
    private requirementsService: RequirementsService,
    private _snackBar: SnackbarDefaultService,
    public dialogRef: MatDialogRef<DialogRequirementsRegisterComponent>,
    private dialog: MatDialog
  ) {
    this.type = this.data?.type;
  }

  dataSource = new MatTableDataSource();

  ngOnInit(): void {
    this.typeTable();
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }

  typeTable() {
    if (this.type === 'Produtos') {
      this.title = 'Selecione o(s) tipo(s) de produto';
      this.displayedColumns.splice(1, 0, 'produtos');
      this.columnA = 'produtos';
      this.dataSource.data = produto_options;
      this.visibleQuantity = !this.visibleQuantity
    } else if (this.type === 'Veículos') {
      this.title = 'Selecione o(s) tipo(s) de veículo';
      this.displayedColumns.splice(1, 0, 'veiculos');
      this.columnA = 'veiculos';
      this.dataSource.data = veiculo_options;
    } else if (this.type === "UF's atendidas") {
      this.title = 'Selecione a(s) UF(s)';
      this.displayedColumns.splice(1, 0, 'ufs');
      this.columnA = 'ufs';
      this.dataSource.data = uf_options_requisitos;
      this.visibleQuantity = !this.visibleQuantity
    } else {
      this.title = 'Selecione o(s) tipo(s) de carga';
      this.displayedColumns.splice(1, 0, 'cargas');
      this.columnA = 'cargas';
      this.dataSource.data = carga_options;
      this.visibleQuantity = !this.visibleQuantity
    }
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  toggleAllRows() {
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }

    this.selection.select(...this.dataSource.data);
  }

  addRegister() {
    if (this.type === 'Produtos') {
      this.saveProduct();
    } else if (this.type === 'Veículos') {
      this.saveVehicle();
    } else if (this.type === "UF's atendidas") {
      this.saveUF();
    } else {
      this.saveLoad();
    }
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  saveData(
    serviceFunction: (obj: any) => Observable<any>,
    successMessage: string,
    errorMessage: string
  ) {

    let obj;

    if(this.type === 'Veículos'){
      obj = this.buildVehicle();
    } else {
      obj = {
        empresa: {
          id: this.data?.id,
          possuiVeiculosComRastreador: this.data?.possuiVeiculosComRastreador
        },
        lookup: this.selection.selected.map((item) => item.value)
      };
    }

    serviceFunction(Object.assign(obj))
      .pipe(first())
      .subscribe({
        next: () => {
          this._snackBar.openSnackBarSuccess(successMessage);
          this.dialogRef.close(true);
        },
        error: () => {
          this._snackBar.openSnackBarError(errorMessage);
        }
  });
  }

  saveProduct() {
    this.saveData(
      this.requirementsService.saveProduct.bind(this.requirementsService),
      'Produto adicionado',
      'Erro ao Adicionar Produto'
    );
  }
  saveUF() {
    this.saveData(
      this.requirementsService.saveUF.bind(this.requirementsService),
      'UF Adicionada',
      'Erro ao Adicionar UF'
    );
  }
  saveVehicle() {
    this.saveData(
      this.requirementsService.saveVeiculo.bind(this.requirementsService),
      'Veiculo Adicionado',
      'Erro ao Adicionar Veiculo'
    );
  }

  saveLoad() {
    this.saveData(
      this.requirementsService.saveCarga.bind(this.requirementsService),
      'Carga Adicionada',
      'Erro ao Adicionar Carga'
    );
  }

  public handlePageEvent(e: PageEvent) {
    if (this.size != e.pageSize) {
      this.page = 0;
    } else {
      this.page = e.pageIndex;
    }
    this.size = e.pageSize;
  }

  buildVehicle() {
    this.vehicles = this.selection.selected;

    for(let v of this.vehicles){
      (this.quantityForm.controls["veiculos"] as FormArray).push(this.formBuilder.group({
        veiculo: [v.value, Validators.required],
        quantidade:[0]
      }))
    }

    const obj = {
      empresa: {
        id: this.data?.id,
        possuiVeiculosComRastreador: this.data?.possuiVeiculosComRastreador
      },
      lookup: this.selection.selected.map((item) => item.value),
      veiculoList: this.quantityForm.value["veiculos"]
    };

    return obj;
  }
}
