<mat-card class="container-details card-container">
    <mat-card-content>
      <mat-card class="card-details">
        <mat-card-content>
          <h6>Informações</h6>
          <div *ngIf="supplier">
            <div fxLayout="row" fxLayoutAlign="start" class="mt-4">
              <div fxLayout="column" fxFlex="25">
                <p class="informations-title">
                  {{ nameColumns.lbName }}
                </p>
                <p class="informations-content">
                  {{ supplier.name }}
                </p>
              </div>
              <div fxLayout="column" fxFlex="25">
                <p class="informations-title">
                  {{ nameColumns.lbDocument }}
                </p>
                <p class="informations-content">
                  {{ formatCNPJ(supplier.document) }}
                </p>
              </div>
              <div fxLayout="column" fxFlex="25">
                <p class="informations-title">
                  {{ nameColumns.lbactivitySector }}
                </p>
                <p class="informations-content">
                  {{ supplier.activitySector }}
                </p>
              </div>
            </div>
  
            <div fxLayout="row" fxLayoutAlign="start" class="mt-2">
              <div fxLayout="column" fxFlex="25">
                <p class="informations-title">
                  {{ nameColumns.lbZipcode }}
                </p>
                <p class="informations-content">
                  {{ supplier.address.zipcode }}
                </p>
              </div>
              <div fxLayout="column" fxFlex="25">
                <p class="informations-title">
                  {{ nameColumns.lbProvince }}
                </p>
                <p class="informations-content">
                  {{ supplier.address.province }}
                </p>
              </div>
              <div fxLayout="column" fxFlex="25">
                <p class="informations-title">
                  {{ nameColumns.lbCity }}
                </p>
                <p class="informations-content">
                  {{ supplier.address.city }}
                </p>
              </div>
            </div>
  
            <div fxLayout="row" fxLayoutAlign="space-between" class="mt-2">
              <div fxLayout="column" fxFlex>
                <p class="informations-title">
                  {{ nameColumns.lbStreet }}
                </p>
                <p class="informations-content">
                  {{ supplier.address.street }}
                </p>
              </div>
              <div fxLayout="column" fxFlex>
                <p class="informations-title">
                  {{ nameColumns.lbnumber }}
                </p>
                <p class="informations-content">
                  {{ supplier.address.number }}
                </p>
              </div>
              <div fxLayout="column" fxFlex>
                <p class="informations-title">
                  {{ nameColumns.lbNeighborhood }}
                </p>
                <p class="informations-content">
                  {{ supplier.address.neighborhood }}
                </p>
              </div>
              <div fxLayout="column" fxFlex>
                <p class="informations-title">
                  {{ nameColumns.lbComplement }}
                </p>
                <p
                  *ngIf="supplier.address.complement"
                  class="informations-content"
                >
                  {{ supplier.address.complement }}
                </p>
                <p
                  *ngIf="!supplier.address.complement"
                  class="informations-content"
                >
                  -
                </p>
              </div>
            </div>
          </div>
        </mat-card-content>
      </mat-card>
    </mat-card-content>
  </mat-card>
  