<hr />
<div>
  <button
  id="btn-attachment"
  mat-button
  class="btn-green"
  (click)="openDialogAttachment()"
>
  <i
    class="ri-upload-2-line"
    style="margin-right: 5px"
  ></i>
  Anexar arquivo
</button>
</div>

<form class="mt-3 px-2" [formGroup]="formCertifications">
  <div fxLayout="row" fxLayoutAlign="space-between" class="mb-3">
    <div fxLayout="column" fxFlex class="right">
      <div class="form-group">
        <label class="label-input form-label required" for="certifies">
          Certificações
        </label>
        <ng-select
          #certificationRef
          name="certifies"
          formControlName="certifies"
          [items]="certifies"
          [multiple]="false"
          [closeOnSelect]="false"
          [searchable]="true"
          [clearable]="false"
          bindLabel="label"
          bindValue="value"
          [ngClass]="{
            'invalid is-invalid':
              formCertifications.get('certifies')?.invalid &&
              formCertifications.get('certifies')?.touched,
            'valid is-valid':
              formCertifications.get('certifies')?.valid &&
              formCertifications.get('certifies')?.touched &&
              formCertifications.get('certifies')?.value.length > 0
          }"
        ></ng-select>
        <div
          *ngIf="
            formCertifications.get('certifies')?.invalid &&
            formCertifications.get('certifies')?.touched &&
            formCertifications.get('certifies')?.value.length == 0
          "
          class="invalid-feedback label-mandatory"
        >
          Campo obrigatório*
        </div>
      </div>
    </div>
    <div fxLayout="column" fxFlex class="left">
      <div class="form-group">
        <div class="d-flex">
          <div class="flex-grow-1">
            <label class="label-input form-label required" for="expirationDate">
              Validade
            </label>
          </div>
        </div>
        <mat-form-field appearance="outline" class="field-date">
          <input
            #expirationDateRef
            id="expirationDate"
            class="form-field"
            matInput
            [matDatepicker]="expirationDate"
            name="expirationDate"
            placeholder="dd/mm/yyyy"
            formControlName="expirationDate"
            required
            [min]="minDate"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="expirationDate"
          ></mat-datepicker-toggle>
          <mat-datepicker #expirationDate></mat-datepicker>
          <mat-error class="label-mandatory" style="margin-left: -15px;" *ngIf="formCertifications.get('expirationDate').invalid">Campo obrigatório!</mat-error>
        </mat-form-field>
      </div>
    </div>
  </div>
  <button (click)="saveCertification()" mat-flat-button class="btn-grey mt-3">
    <i class="ri-add-line"></i> Adicionar certificação
  </button>
</form>

<div class="px-2">
  <h6 class="mt-4">Minhas certificações</h6>
</div>

<div class="mt-2 px-2">
  <table mat-table [dataSource]="dataSource" class="table mt-4">
    <caption hidden>
      Tabela certificações
    </caption>
    <ng-container matColumnDef="certifies">
      <th mat-header-cell *matHeaderCellDef class="table-head-color">
        {{ headerTable.lbCertifies }}
      </th>
      <td mat-cell *matCellDef="let element" class="table-row">
        {{ element.certifies }}
      </td>
    </ng-container>

    <ng-container matColumnDef="expirationDate">
      <th mat-header-cell *matHeaderCellDef class="table-head-color">
        {{ headerTable.lbExpirationDate }}
      </th>
      <td mat-cell *matCellDef="let element" class="table-row">
        {{ element.expirationDate }}
      </td>
    </ng-container>
    <ng-container matColumnDef="attachment">
      <th mat-header-cell *matHeaderCellDef class="table-head-color">
        {{ headerTable.lbAttachment }}
      </th>
      <td mat-cell *matCellDef="let element" class="table-row">
        <a
          target="_blank"
          [href]="element.attachment"
          mat-flat-button
          class="btn-grey"
          [disabled]="!element.attachment"
        >
          {{ element.attachment ? "Arquivo" : "Sem Anexo" }}</a
        >
      </td>
    </ng-container>

    <ng-container matColumnDef="actions">
      <th
        mat-header-cell
        *matHeaderCellDef
        class="table-head-color column-actions"
      >
        {{ headerTable.lbActions }}
      </th>
      <td
        mat-cell
        *matCellDef="let element; let rowIndex = index"
        class="table-row"
      >
        <button
          class="btn-trash"
          (click)="removeItem(element.certifiesWithUndescore)"
        >
          <i class="ri-delete-bin-5-fill"></i>
        </button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
  <div class="add-hover">
  </div>
</div>
