<p>my-supplier-form works!</p>
<mat-card class="container-my-suppliers card-container">
    <mat-card-content>
      <h6 *ngIf="!editSupplier" class="mt-2">Cadastro de fornecedor</h6>
      <h6 *ngIf="editSupplier" class="mt-2">Edição de fornecedor</h6>
  
      <div class="d-flex flex-row">
        <div class="mt-4 w-100">
          <form [formGroup]="supplierForm">
            <div class="row mb-2">
              <div class="col col-md-6 form-group position-relative">
                <label class="label-input form-label required" for="document">
                  CNPJ
                </label>
                <input
                  #documentRef
                  matInput
                  mask="00.000.000/0000-00"
                  name="document"
                  class="form-control input-data w-100"
                  formControlName="document"
                  placeholder="Digite o CNPJ"
                  required
                  [ngClass]="{
                      'is-invalid':
                      supplierForm.get('document')?.invalid &&
                      supplierForm.get('document')?.touched,
                      'is-valid':
                      supplierForm.get('document')?.valid &&
                      supplierForm.get('document')?.touched || supplierForm.get('document')?.valid,
                    }"
                />
                <div
                  *ngIf="
                    supplierForm.get('document')?.invalid &&
                    supplierForm.get('document')?.touched &&
                    supplierForm.get('document')?.value?.length == 0
                  "
                  class="invalid-feedback label-mandatory"
                >
                  Campo obrigatório*
                </div>
                <div
                  *ngIf="
                    (supplierForm.get('document').value?.length > 0 &&
                      supplierForm.get('document').value?.length < 18 &&
                      supplierForm.get('document')?.touched &&
                      supplierForm.get('document')?.errors) ||
                    supplierForm.get('document')?.errors && supplierForm.get('document')?.value?.length > 0
                  "
                  class="invalid-feedback label-mandatory"
                >
                  CNPJ inválido!
                </div>
              </div>
              <div class="col col-md-6 form-group">
                <label class="label-input form-label required" for="legalName">
                  Razão social
                </label>
                <input
                  #legalNameRef
                  matInput
                  name="legalName"
                  class="form-control input-data w-100"
                  formControlName="legalName"
                  placeholder="Digite a razão social"
                  required
                  [ngClass]="{
                    'is-invalid':
                    supplierForm.get('legalName')?.invalid &&
                    supplierForm.get('legalName')?.touched,
                    'is-valid':
                    supplierForm.get('legalName')?.valid &&
                    supplierForm.get('legalName')?.touched || supplierForm.get('legalName')?.valid,
                  }"
                />
                <div
                  *ngIf="
                    supplierForm.get('legalName')?.invalid &&
                    supplierForm.get('legalName')?.touched
                  "
                  class="invalid-feedback label-mandatory"
                >
                  Campo obrigatório*
                </div>
              </div>
            </div>
            <div class="row mb-2">
              <div class="col col-md-6 form-group">
                <label class="label-input form-label required" for="activitySector">
                  Setor de Atuação
                </label>
                <ng-select
                  #activitySectorRef
                  name="activitySector"
                  formControlName="activitySector"
                  [items]="activitySectorOptions"
                  [closeOnSelect]="true"
                  [searchable]="true"
                  [clearable]="false"
                  bindLabel="label"
                  bindValue="value"
                  required
                  [ngClass]="{
                    'invalid is-invalid':
                      supplierForm.get('activitySector')?.invalid &&
                      supplierForm.get('activitySector')?.touched &&
                      supplierForm.get('activitySector').value === '',
                    'valid is-valid':
                      supplierForm.get('activitySector')?.valid &&
                      supplierForm.get('activitySector')?.touched &&
                      supplierForm.get('activitySector').value !== '' || supplierForm.get('activitySector')?.valid ,
                  }"
                >
                  <ng-template ng-label-tmp let-item="item" let-index="index">
                    {{ item.label }} 
                  </ng-template>
                </ng-select>
                <div
                *ngIf="
                  supplierForm.get('activitySector')?.invalid &&
                  supplierForm.get('activitySector').value === ''
                "
                class="invalid-feedback label-mandatory"
              >
                Campo obrigatório*
              </div>
              </div>
              <div class="col col-md-6 form-group">
                <label class="label-input form-label required" for="zipcode">
                  CEP
                </label>
                <input
                  #zipocodeRef
                  matInput
                  mask="00000-000"
                  name="zipcode"
                  class="form-control input-data w-100"
                  formControlName="zipcode"
                  placeholder="Digite o CEP"
                  required
                  [ngClass]="{
                    'is-invalid':
                    supplierForm.get('zipcode')?.invalid &&
                    supplierForm.get('zipcode')?.touched,
                    'is-valid':
                    supplierForm.get('zipcode')?.valid &&
                    supplierForm.get('zipcode')?.touched || supplierForm.get('zipcode')?.valid,
                  }"
                />
                <div
                  *ngIf="
                    supplierForm.get('zipcode')?.invalid &&
                    supplierForm.get('zipcode')?.touched &&
                    supplierForm.get('zipcode').value?.length == 0
                  "
                  class="invalid-feedback label-mandatory"
                >
                  Campo obrigatório*
                </div>
                <div
                  *ngIf="
                    supplierForm.get('zipcode').value?.length > 0 &&
                    supplierForm.get('zipcode').value?.length < 10 &&
                    supplierForm.get('zipcode')?.touched &&
                    supplierForm.get('zipcode')?.errors
                  "
                  class="invalid-feedback label-mandatory"
                >
                  CEP inválido!
                </div>
              </div>
            </div>
            <div class="row mb-2">
              <div class="col col-md-6 form-group">
                <label class="label-input form-label required" for="province">
                  Estado
                </label>
                <ng-select
                  #provinceRef
                  name="province"
                  formControlName="province"
                  [items]="UFs"
                  [closeOnSelect]="true"
                  [searchable]="true"
                  [clearable]="false"
                  bindLabel="label"
                  bindValue="value"
                  required
                  [ngClass]="{
                    'invalid is-invalid':
                      supplierForm.get('province')?.invalid &&
                      supplierForm.get('province')?.touched &&
                      supplierForm.get('province').value === '',
                    'valid is-valid':
                      supplierForm.get('province')?.valid &&
                      supplierForm.get('province')?.touched &&
                      supplierForm.get('province').value !== '' || supplierForm.get('province')?.valid ,
  
                  }"
                >
                  <ng-template ng-label-tmp let-item="item" let-index="index">
                    {{ item.value }}
                  </ng-template>
                </ng-select>
                <div
                  *ngIf="
                    supplierForm.get('province')?.invalid &&
                    supplierForm.get('province').value === ''
                  "
                  class="invalid-feedback label-mandatory"
                >
                  Campo obrigatório*
                </div>
              </div>
              <div class="col col-md-6 form-group">
                <label class="label-input form-label required" for="city">
                  Cidade
                </label>
                <input
                  #cityRef
                  matInput
                  name="city"
                  class="form-control input-data w-100"
                  formControlName="city"
                  placeholder="Digite o nome da cidade"
                  required
                  [ngClass]="{
                    'is-invalid':
                    supplierForm.get('city')?.invalid &&
                    supplierForm.get('city')?.touched,
                    'is-valid':
                    supplierForm.get('city')?.valid &&
                    supplierForm.get('city').value !== '',
                  }"
                />
                <div
                  *ngIf="
                    supplierForm.get('city')?.invalid &&
                    supplierForm.get('city')?.touched
                  "
                  class="invalid-feedback label-mandatory"
                >
                  Campo obrigatório*
                </div>
              </div>
            </div>
            <div class="row mb-2">
              <div class="col col-md-6 form-group">
                <label class="label-input form-label required" for="street">
                  Logradouro
                </label>
                <input
                  #streetRef
                  matInput
                  name="street"
                  class="form-control input-data w-100"
                  formControlName="street"
                  placeholder="Digite o a logradouro"
                  required
                  [ngClass]="{
                    'is-invalid':
                      supplierForm.get('street')?.invalid &&
                      supplierForm.get('street')?.touched,
                    'is-valid':
                      supplierForm.get('street')?.valid &&
                      supplierForm.get('street').value !== ''
                  }"
                />
                <div
                  *ngIf="
                    supplierForm.get('street')?.invalid &&
                    supplierForm.get('street')?.touched
                  "
                  class="invalid-feedback label-mandatory"
                >
                  Campo obrigatório*
                </div>
              </div>
              <div class="col col-md-6 form-group">
                <label class="label-input form-label required" for="number">
                  Número
                </label>
                <input
                  #numberRef
                  matInput
                  name="number"
                  class="form-control input-data w-100"
                  formControlName="number"
                  placeholder="Digite o número"
                  required
                  [ngClass]="{
                    'is-invalid':
                    supplierForm.get('number')?.invalid &&
                    supplierForm.get('number')?.touched,
                    'is-valid':
                    supplierForm.get('number')?.valid &&
                    supplierForm.get('number')?.touched || supplierForm.get('number')?.valid,
                  }"
                />
                <div
                  *ngIf="
                    supplierForm.get('number')?.invalid &&
                    supplierForm.get('number')?.touched
                  "
                  class="invalid-feedback label-mandatory"
                >
                  Campo obrigatório*
                </div>
              </div>
            </div>
            <div class="row mb-2">
              <div class="col col-md-6 form-group">
                <label class="label-input form-label required" for="neighborhood">
                  Bairro
                </label>
                <input
                  #neighborhoodRef
                  matInput
                  name="neighborhood"
                  class="form-control input-data w-100"
                  formControlName="neighborhood"
                  placeholder="Digite um bairro"
                  required
                  [ngClass]="{
                    'is-invalid':
                      supplierForm.get('neighborhood')?.invalid &&
                      supplierForm.get('neighborhood')?.touched,
                    'is-valid':
                      supplierForm.get('neighborhood')?.valid &&
                      supplierForm.get('neighborhood').value !== ''
                  }"
                />
                <div
                  *ngIf="
                    supplierForm.get('neighborhood')?.invalid &&
                    supplierForm.get('neighborhood')?.touched
                  "
                  class="invalid-feedback label-mandatory"
                >
                  Campo obrigatório*
                </div>
              </div>
              <div class="col col-md-6 form-group">
                <label class="label-input form-label" for="complement">
                  Complemento
                </label>
                <input
                  matInput
                  name="complement"
                  class="form-control input-data w-100"
                  formControlName="complement"
                  placeholder="Digite um complemento"
                  [ngClass]="{
                    'is-valid':
                      (supplierForm.get('complement')?.valid &&
                        supplierForm.get('complement').value !== '') ||
                      supplierForm.get('complement')?.valid && supplierForm.get('complement')?.value?.length > 0
                  }"
                />
              </div>
             
            </div>
  
            <div class="row justify-content-end">
              <div class="col col-md-2 text-end mt-3">
                <button
                  mat-flat-button
                  class="btn-green"
                  (click)="submitSupplierData()"
                >
                  Salvar
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
  