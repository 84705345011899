<div class="container-requirements">
  <mat-dialog-title>
    <h6 class="mt-2">{{ title }}</h6></mat-dialog-title
  >
    <form
      class="select-form d-flex"
      [formGroup]="searchForm"
    >
      <div class="form-group position-relative align-search">
        <input
          matInput
          id="search"
          name="search"
          formControlName="search"
          type="text"
          class="form-control pe-5 input-data w-100"
          placeholder="Faça sua busca aqui"
          (keyup)="applyFilter($event)"
        />
        <i
          class="ri-search-line position-absolute end-0 my-1 mx-2 text-center"
          style="top: 5px"
        ></i>
      </div>
    </form>
    <div class="table-container">
      <table mat-table [dataSource]="dataSource">
        <caption hidden></caption>
        <!-- Checkbox Column -->
        <ng-container matColumnDef="select">
          <th mat-header-cell *matHeaderCellDef class="table-head-color">
            <mat-checkbox
              (change)="$event ? toggleAllRows() : null"
              [checked]="selection.hasValue() && isAllSelected()"
              [indeterminate]="selection.hasValue() && !isAllSelected()"
            >
            </mat-checkbox>
          </th>
          <td mat-cell *matCellDef="let row">
            <mat-checkbox
              (click)="$event.stopPropagation()"
              (change)="$event ? selection.toggle(row) : null"
              [checked]="selection.isSelected(row)"
            >
            </mat-checkbox>
          </td>
        </ng-container>

        <ng-container matColumnDef="{{ columnA }}">
          <th mat-header-cell *matHeaderCellDef class="table-head-color">
            {{ type }}
          </th>
          <td mat-cell *matCellDef="let element" class="table-row">
            {{ element.label }}
          </td>
        </ng-container>

        <ng-container matColumnDef="description">
          <th mat-header-cell *matHeaderCellDef class="table-head-color">
            Descrição
          </th>
          <td mat-cell *matCellDef="let element" class="table-row">-</td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr
          mat-row
          *matRowDef="let row; columns: displayedColumns"
          (click)="selection.toggle(row)"
        ></tr>
      </table>
    </div>
      <mat-paginator
        [pageSizeOptions]="[5, 10, 20, 50, 100]"
        showFirstLastButtons
        aria-label="Select page of periodic elements"
        (page)="handlePageEvent($event)"
        [length]="totalElements"
        [pageIndex]="page"
        [pageSize]="size"
      >
      </mat-paginator>

  <div class="d-flex flex-row-reverse mt-2">
    <button
      mat-flat-button
      class="btn-green"
      cdkFocusInitial
      (click)="addRegister()"
      [disabled]="selection.selected.length == 0"
      [hidden]="visibleTable"
    >
      Salvar
    </button>
    <button
      mat-flat-button
      mat-dialog-close
      class="btn-grey"
      [hidden]="visibleTable"
      style="margin-right: 10px"
    >
      Voltar
    </button>
  </div>
</div>
