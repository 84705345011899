import { Component, ElementRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SnackbarDefaultService } from 'src/app/shared/services/snackbar-default.service';
import { CertificationService } from 'src/app/configurations-carrier/certifications/certification.service';
import { ActivatedRoute, Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { MatDialog } from '@angular/material/dialog';
import { DialogConfirmComponent } from 'src/app/shared/dialog-confirm/dialog-confirm.component';
import { UFs, setor_atuacao_options } from 'src/app/shared/const/consts';
import { CepService } from 'src/app/shared/services/cep.service';
import { DialogCompanyCreatedComponent } from '../dialog-company-created/dialog-company-created.component';
import { NgSelectComponent } from '@ng-select/ng-select';

@Component({
  selector: 'app-general-information',
  templateUrl: './general-information.component.html',
  styleUrls: ['./general-information.component.scss'],
})
export class GeneralInformationComponent {
  public generalForm: FormGroup = this.formBuilder.group({
    id: [''],
    legalName: ['', Validators.required],
    // companyName: ['', Validators.required],
    document: ['', Validators.required],
    zipcode: ['', [Validators.required]],
    province: ['', [Validators.required]],
    city: ['', [Validators.required]],
    street: ['', [Validators.required]],
    number: ['', [Validators.required]],
    neighborhood: ['', [Validators.required]],
    complement: [''],
    insurance: [''],
    patrimonialCost: [''],
    hasTracker: [''],
    logo: [''],
    activitySector: ['', [Validators.required]],
  });

  files: File[] = [];
  logo: any;
  updateLogo: boolean = false;
  link: string;
  user = JSON.parse(localStorage.getItem('usuario')!);
  companyIdentifier: any;
  activitySectorOptions = setor_atuacao_options;
  addLogo: any;

  UFs = UFs;

  @ViewChild('legalNameRef') legalNameRef: ElementRef;
  @ViewChild('documentRef') documentRef: ElementRef;
  @ViewChild('zipCodeRef') zipCodeRef: ElementRef;
  @ViewChild('provinceRef') provinceRef: NgSelectComponent;
  @ViewChild('cityRef') cityRef: ElementRef;
  @ViewChild('streetRef') streetRef: ElementRef;
  @ViewChild('numberRef') numberRef: ElementRef;
  @ViewChild('neighborhoodRef') neighborhoodRef: ElementRef;
  @ViewChild('activitySectorRef') activitySectorRef: NgSelectComponent;

  constructor(
    private formBuilder: FormBuilder,
    private _snackBar: SnackbarDefaultService,
    private certificationService: CertificationService,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private cepService: CepService,
    private router: Router,
  ) {}

  ngOnInit(): void {
    setTimeout(() => {
      window.scroll(0, 0);
    }, 1000);
    this.companyIdentifier = this.getIdFromUrl();
    if (this.companyIdentifier) {
      this.getCompany();
    }
  }

  updatePhoto(event: any) {
    this.updateLogo = true;
    let img = document.createElement('img');
    let urlImage = URL.createObjectURL(event.target.files[0]);
    let width = 0;
    let height = 0;
    img.src = urlImage;
    img.onload = () => {
      width = img.naturalWidth;
      height = img.naturalHeight;

      if (width > 400 || height > 400) {
        this._snackBar.openSnackBarInfo(
          'Imagem com dimensões fora dos padrões aceitos'
        );
      } else {
        this.logo = urlImage;
          this.certificationService
            .salvaImagem(event.target.files[0], this.generalForm.value.id)
            .pipe(first())
            .subscribe({
              next: (url) => {
                if (url != null) {
                  this.link = environment.AWS_S3_ASSETS + url;
                }
              },
              error: (error) => {
                this._snackBar.openSnackBarError('Não foi possível salvar a imagem');
              },
            });
      }
    };
  }

  submitForm() {
    if(this.generalForm.invalid) {
      this._snackBar.openSnackBarWarning('Há campos obrigatórios não preenchidos.');
      if(this.generalForm.controls['legalName'].invalid) {
        this.legalNameRef.nativeElement.focus();
      } else if(this.generalForm.controls['document'].invalid) {
        this.documentRef.nativeElement.focus();
      } else if(this.generalForm.controls['zipcode'].invalid) {
        this.zipCodeRef.nativeElement.focus();
      }
      else if(this.generalForm.controls['province'].invalid) {
        this.provinceRef.focus();
      }
      else if(this.generalForm.controls['city'].invalid) {
        this.cityRef.nativeElement.focus();
      }
      else if(this.generalForm.controls['street'].invalid) {
        this.streetRef.nativeElement.focus();
      }
      else if(this.generalForm.controls['number'].invalid) {
        this.numberRef.nativeElement.focus();
      }
      else if(this.generalForm.controls['neighborhood'].invalid) {
        this.neighborhoodRef.nativeElement.focus();
      }
      else if(this.generalForm.controls['activitySector'].invalid) {
        this.activitySectorRef.focus();
      }
      return;
    }

    let transportadora = {
      cnpj: this.generalForm.value.document,
      razaoSocial: this.generalForm.value.legalName,
      valorPatrimonial: this.generalForm.value.patrimonialCost,
      numeroSeguro: this.generalForm.value.insurance,
      veiculosComRastreador: this.generalForm.value.hasTracker,
      endereco: {
        uf: this.generalForm.value.province,
        cidade: this.generalForm.value.city,
        cep: this.generalForm.value.zipcode,
        logradouro: this.generalForm.value.street,
        numero: this.generalForm.value.number,
        complemento: this.generalForm.value.complement,
        bairro: this.generalForm.value.neighborhood,
      },
      setorAtuacao: this.generalForm.value.activitySector,
    };

    if (this.companyIdentifier) {
      Object.assign(transportadora, { id: this.generalForm.value.id });
      Object.assign(transportadora, { logo: this.link });
      this.certificationService
        .editCompany(transportadora)
        .pipe(first())
        .subscribe({
          next: () => {
            this._snackBar.openSnackBarSuccess('Edição realizada com sucesso.');
          },
          error: () => {
            this._snackBar.openSnackBarError('Erro ao editar.');
          },
        });
    } else {
      this.certificationService
        .createCompany(transportadora)
        .pipe(first())
        .subscribe({
          next: (data) => {
            this.companyIdentifier = data.id;
            const dialogRef = this.dialog.open(DialogCompanyCreatedComponent, {
              data: {
                companyName: this.generalForm.value.legalName,
                idCompany: this.companyIdentifier
              },
            })
      
            dialogRef.afterClosed().subscribe((result) => {
              if (result == 'carriers') {
                this.router.navigate([result]);
              } else if (/new-company/.exec(result)) {
                location.reload();
              } else if (/edit-company/.exec(result)) {
                this.router.navigate([result]);
              } else {
                this.redirect();
              }
            })
          },
          error: () => {
            this._snackBar.openSnackBarError('Erro ao cadastrar.');
          },
        });
    }
  }

  public redirect() {
    this.router.navigate([`configurations-carrier/companies`]).catch((err) => err);
  }

  getIdFromUrl() {
    return this.route.snapshot.paramMap.get('id');
  }

  private fillInFields(data: any) {
    this.generalForm.get('id').setValue(data.id);
    this.generalForm.get('document').setValue(data.cnpj);
    this.generalForm.get('legalName').setValue(data.razaoSocial);
    this.generalForm.get('zipcode').setValue(data.endereco.cep);
    this.generalForm.get('province').setValue(data.endereco.uf);
    this.generalForm.get('city').setValue(data.endereco.cidade);
    this.generalForm.get('street').setValue(data.endereco.logradouro);
    this.generalForm.get('number').setValue(data.endereco.numero);
    this.generalForm.get('neighborhood').setValue(data.endereco.bairro);
    this.generalForm.get('complement')?.setValue(data.endereco.complemento);
    this.generalForm.get('patrimonialCost')?.setValue(data.valorPatrimonial);
    this.generalForm.get('insurance')?.setValue(data.numeroSeguro);
    this.generalForm
      .get('hasTracker')
      ?.setValue(data.possuiVeiculosComRastreador ? 'true' : 'false');

    let setor = this.activitySectorOptions.find(
      (option) => option.value === data.setorAtuacao
    );
    if (!setor) {
      setor = this.activitySectorOptions.find(
        (option) => option.value === 'naoInformado'
      );
    }
    this.generalForm.get('activitySector')?.setValue(setor.value);
  }

  getCompany() {
    this.certificationService
      .getCompany(this.getIdFromUrl())
      .pipe()
      .subscribe((data) => {
        if (data.logo) {
          this.logo = environment.AWS_S3_ASSETS + data.logo;
        } else {
          this.logo = 'assets/img/svg/caminhao.svg';
        }
        this.fillInFields(data);
      });
  }

  removeLogo() {
    const dialogRef = this.dialog.open(DialogConfirmComponent, {
      data: {
        title: 'Confirmação',
        description: 'Tem certeza que deseja remover a logo?',
      },
    });

    if (this.companyIdentifier) {
      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          this.certificationService
            .removeLogo(this.generalForm.value.id)
            .pipe()
            .subscribe((data) => {
              this.logo = 'assets/img/svg/caminhao.svg';
              this._snackBar.openSnackBarSuccess('Logo removida com sucesso');
            });
        }
      });
    } else {
      this.logo = 'assets/img/svg/caminhao.svg';
      this._snackBar.openSnackBarSuccess('Logo removida com sucesso');
    }
  }

  consultaCEP() {
    let cep = this.generalForm.get('zipcode')?.value;

    if (cep) {
      this.cepService
        .consultaCep(cep)
        .pipe(first())
        .subscribe((dados: any) => {
          if (!dados.erro) {
            this.setValuesCEP(dados);
          } else {
            this.clearValuesCEP();
          }
        });
    }
  }

  setValuesCEP(dados: any) {
    this.generalForm.get('city')?.setValue(dados.localidade);
    this.generalForm.get('province')?.setValue(dados.uf);
    this.generalForm.get('street')?.setValue(dados.logradouro);
    this.generalForm.get('neighborhood')?.setValue(dados.bairro);
  }

  clearValuesCEP() {
    this.generalForm.get('city')?.setValue('');
    this.generalForm.get('province')?.setValue('');
    this.generalForm.get('street')?.setValue('');
    this.generalForm.get('neighborhood')?.setValue('');
  }
}
