import { NO_ERRORS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { ConfigurationsShipperRoutingModule } from './configurations-shipper-routing.module';
import { ConfigurationsShipperComponent } from './configurations-shipper.component';
import { MyCompaniesComponent } from './my-companies/my-companies.component';
import { MyCompanyFormComponent } from './my-company-form/my-company-form.component';
import { MyProfileComponent } from './my-profile/my-profile.component';
import { MyUserFormComponent } from './my-user-form/my-user-form.component';
import { MyUsersComponent } from './my-users/my-users.component';

import { MatToolbarModule } from '@angular/material/toolbar';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatChipsModule } from '@angular/material/chips';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatProgressBarModule } from '@angular/material/progress-bar';

import { CdkAccordionModule } from '@angular/cdk/accordion';

import { SharedModule } from '../shared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { UserTypeComponent } from './user-type/user-type.component';
import { IntegrationsComponent } from './integrations/integrations.component';
import { MyRecipientComponent } from './my-recipient/my-recipient.component';
import { RecipientComponent } from './recipient/recipient.component';
import { RecipientDetailsComponent } from './recipient-details/recipient-details.component';
import { OfferContractComponent } from './offer-contract/offer-contract.component';
import { MyAccountComponent } from './my-account/my-account.component';
import { MySuppliersComponent } from './my-suppliers/my-suppliers.component';
import { MySupplierFormComponent } from './my-supplier-form/my-supplier-form.component';
import { SupplierDetailsComponent } from './supplier-details/supplier-details.component';
import { DialogAddDefaultProductComponent } from './dialog-add-default-product/dialog-add-default-product.component';
import { MatDialogModule } from '@angular/material/dialog';
import { EnvironmentRulesComponent } from './environment-rules/environment-rules.component';

@NgModule({
  declarations: [
    ConfigurationsShipperComponent,
    MyCompaniesComponent,
    MyCompanyFormComponent,
    MyProfileComponent,
    MyUserFormComponent,
    MyUsersComponent,
    UserTypeComponent,
    IntegrationsComponent,
    MyRecipientComponent,
    RecipientComponent,
    RecipientDetailsComponent,
    OfferContractComponent,
    MySuppliersComponent,
    MySupplierFormComponent,
    SupplierDetailsComponent,
    MyAccountComponent,
    DialogAddDefaultProductComponent,
    EnvironmentRulesComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    ConfigurationsShipperRoutingModule,
    SharedModule,
    MatToolbarModule,
    MatCardModule,
    MatButtonModule,
    MatSidenavModule,
    MatListModule,
    MatMenuModule,
    MatChipsModule,
    MatSlideToggleModule,
    MatCheckboxModule,
    MatTableModule,
    MatPaginatorModule,
    NgSelectModule,
    NgxMaskDirective,
    NgxMaskPipe,
    CdkAccordionModule,
    FlexLayoutModule,
    MatProgressBarModule,
    MatDialogModule,
  ],
  schemas: [NO_ERRORS_SCHEMA],
  providers: [provideNgxMask()],
})
export class ConfigurationsShipperModule {}
