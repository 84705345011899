import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SnackbarDefaultService } from 'src/app/shared/services/snackbar-default.service';
import { IntegrationsService } from './integrations.service';
import { first } from 'rxjs';

@Component({
  selector: 'app-integrations',
  templateUrl: './integrations.component.html',
  styleUrls: ['./integrations.component.scss'],
})
export class IntegrationsComponent implements OnInit {
  loaded: boolean = true;
  simulationForm: FormGroup;
  combinedShippingForm: FormGroup;
  cfForm: FormGroup;
  usuario: any;

  constructor(
    private fb: FormBuilder,
    private service: IntegrationsService,
    private _snackBar: SnackbarDefaultService
  ) {
    this.simulationForm = this.fb.group({
      url: ['', [Validators.required]],
      usuario: ['', [Validators.required]],
      password: ['', [Validators.required]],
      database: ['', [Validators.required]],
    });

    this.combinedShippingForm = this.fb.group({
      url: ['', [Validators.required]],
      usuario: ['', [Validators.required]],
      password: ['', [Validators.required]],
      database: ['', [Validators.required]],
    });

    this.cfForm = this.fb.group({
      url: ['', []],
      usuario: ['', [Validators.required]],
      password: ['', [Validators.required]],
      database: ['', []],
    });
  }

  ngOnInit(): void {
    this.usuario = JSON.parse(localStorage.getItem('usuario')!);
    this.getIntegration();
  }

  getIntegration() {
    this.service
      .getIntegration(this.usuario.id)
      .pipe()
      .subscribe((data) => {
        if (data?.length > 0) {
          for(let i of data) {
            this.fillinFields(i)
          }
        }
      });
  }

  private fillinFields(data: any) {
    let form;
    if (data.tipoIntegracao === 'GKO_SIMULACAO') {
      form = this.simulationForm;
    } 
    if (data.tipoIntegracao === 'RETORNO_FRETE_COMBINADO') {
      form = this.combinedShippingForm;
    }
    if (data.tipoIntegracao === 'LOGIN_CF') {
      form = this.cfForm;
    }

    form.get('usuario').setValue(data.usuario);
    form.get('url').setValue(data.url);
    form.get('password').setValue(data.senha);
    form.get('database').setValue(data.banco);
  }

  visiblePassword(event: any, elementId: string) {
    const { target } = event;
    const { checked } = target;
    const element = document.getElementById(elementId);
    element?.setAttribute('type', checked ? 'text' : 'password');
  }

  submitForm(tipoIntegracao: string) {
    let form;
    if (tipoIntegracao === 'GKO_SIMULACAO') {
      form = this.simulationForm;
    } 
    if (tipoIntegracao === 'RETORNO_FRETE_COMBINADO') {
      form = this.combinedShippingForm;
    }
    if (tipoIntegracao === 'LOGIN_CF') {
      form = this.cfForm;
      form.get('url').setValue('https://utilities.confirmafacil.com.br/login/login');
      form.get('database').setValue(null);
    }

    let integration: any = {
      url:form.get('url')?.value,
      usuario: form.get('usuario')?.value,
      senha: form.get('password')?.value,
      banco: form.get('database')?.value,
      tipoIntegracao: tipoIntegracao,
    };

    this.service
      .createIntegration(integration)
      .pipe(first())
      .subscribe({
        next: (data) => {
          this._snackBar.openSnackBarSuccess(
            'Cadastro de integração realizado'
          );
          this.getIntegration();
        },
        error: (error) => {
          if (error.error === 'cliente ja possui credencial identica') {
            this._snackBar.openSnackBarErrorWithDuration(
              `Este cliente já possui uma credencial idêntica.`,
              10000
            );
          } else {
            this._snackBar.openSnackBarErrorWithDuration(
              `Erro ao cadastrar integração`,
              10000
            );
          }
        },
      });
  }
}
