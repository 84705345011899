import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';

import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { finalize, first } from 'rxjs/operators';
import { MaskService } from 'src/app/shared/services/mask.service';
import { SnackbarDefaultService } from 'src/app/shared/services/snackbar-default.service';
import { ConfigurationsCarrierService } from '../configurations-carrier.service';
import { MyProfileService } from './my-profile.service';
import { RedefinePasswordDialogComponent } from 'src/app/shared/redefine-password-dialog/redefine-password-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';
import {ImageService} from 'src/app/shared/services/image.service'
export interface PeriodicElement {
  id: string;
  corporateName: string;
  cnpj: string;
  address: string;
}

export interface HeaderTable {
  lbCorporateName: string;
  lbCnpj: string;
  lbAddress: string;
}

const ELEMENT_DATA: PeriodicElement[] = [];

@Component({
  selector: 'app-my-profile',
  templateUrl: './my-profile.component.html',
  styleUrls: ['./my-profile.component.scss'],
})
export class MyProfileComponent implements OnInit {
  public profileForm: FormGroup = this.formBuilder.group({
    user: ['', Validators.required],
    email: ['', Validators.compose([Validators.required, Validators.email])],
    phone: ['', Validators.required],
  });

  displayedColumns: string[] = [
    'corporateName',
    'cnpj',
    'city',
  ];
  dataSource = new MatTableDataSource(ELEMENT_DATA);

  page: number = 0;
  size: number = 50;
  totalElements: number = 0;
  loadingContent: boolean = false;
  filtro: any = {};
  loaded: boolean = false;
  usuario:any;


  perfilImage: string ;
  @ViewChild(MatPaginator)
  paginator!: MatPaginator;

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }

  headerTable: HeaderTable = {
    lbCorporateName: 'Razão Social',
    lbCnpj: 'CNPJ',
    lbAddress: 'Endereço',
  };

  constructor(
    private formBuilder: FormBuilder,
    private configCarrierService: ConfigurationsCarrierService,
    private myProfileService: MyProfileService,
    private snackBar: SnackbarDefaultService,
    private maskService: MaskService,
    private dialog: MatDialog,
    private imageService: ImageService
  ) {}

  ngOnInit(): void {
    this.usuario = JSON.parse(localStorage.getItem('usuario')!)
    this.profileForm.get('user')!.setValue(this.usuario.nome);
    this.profileForm.get('email')!.setValue(this.usuario.email);
    this.profileForm.get('phone')!.setValue(this.usuario.telefone);

    this.getTransportadoras();

    if(this.usuario.imagem === undefined || this.usuario.imagem === null){
    this.perfilImage = 'assets/img/svg/abstract-user-flat-grey.svg'

  }else{
    this.perfilImage = environment.AWS_S3_ASSETS+this.usuario.imagem

  }



  }

  public submitForm() {
    let perfil = {
      id: this.usuario.id,
      nome: this.profileForm.get('user')!.value,
      telefone: this.profileForm.get('phone')!.value,
    };
    this.configCarrierService
      .atualizaPerfil(perfil)
      .pipe(first())
      .subscribe((dados: any) => {
        if (dados) {
          this.configCarrierService
            .getUserById(this.usuario.id)
            .pipe(first())
            .subscribe((data: any) => {
              this.usuario.nome = data.nome;
              this.usuario.telefone = data.telefone;
              localStorage.setItem('usuario', JSON.stringify(this.usuario));
              this.snackBar.openSnackBarSuccess('Dados alterados com sucesso');
            });
        }
      });
  }

  public getTransportadoras() {
    const paginatorOjb = {
      page: this.page,
      size: this.size,
    };

    const id = this.usuario.id;

    this.configCarrierService
      .getTransportadoras(paginatorOjb, id)
      .pipe(finalize(() => {}))
      .subscribe((result: any) => {
        let transportadoras: PeriodicElement[] = [];
        result.content?.forEach((element: any) => {
          let transportadora: PeriodicElement = {
            id: element.empresa.id,
            corporateName: element.empresa.razaoSocial.toUpperCase(),
            cnpj: element.empresa.cnpj,
            address: this.getAddress(element.empresa),
          };
          transportadoras.push(transportadora);
        });
        this.totalElements = transportadoras.length;
        this.dataSource = new MatTableDataSource(transportadoras);
        this.loaded = true;
      });
  }

  public getAddress(address: any) {
    if(address && address.cidade && address.uf){
    return `${address.cidade} - ${address.uf}`;
    }else{
      return " - "
    }
  }

  public handlePageEvent(e: PageEvent) {
    if (this.size != e.pageSize) {
      this.page = 0;
    } else {
      this.page = e.pageIndex;
    }
    this.size = e.pageSize;
    this.getTransportadoras();
  }

  changePassword() {
    this.dialog.open(RedefinePasswordDialogComponent);
  }


  public updateFile(event: any) {

    this.imageService.updateFile(event);

    setTimeout(() => {
      window.location.reload();
      this.snackBar.openSnackBarSuccess('Imagem alterada com sucesso');
    }, 6000);

}

}

