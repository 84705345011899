<div class="d-flex right">
  <div class="flex-grow-1">
    <span
      class="title"
      [ngClass]="{
        'info': type === 'info',
        'error': type === 'error',
        'warning': type === 'warning',
        'success': type === 'success',
      }"
    >
      <img src="{{ icon }}" alt="" />
      {{ code ? title + ' ' + code : title}}
    </span>
  </div>
  <div class="container-btn">
    <span matSnackBarActions>
      <button
        class="btn-close"
        matSnackBarAction
        (click)="snackBarRef.dismissWithAction()"
      >
        X
      </button>
    </span>
  </div>
</div>

<div fxLayout="row" fxLayoutAlign="space-between" class="right">
  <div fxLayout="column" fxflex>
    <p class="content mt-2" matSnackBarLabel>{{ message }}</p>
    <p *ngIf="link">
      <a href="{{ link }}" target="_blank">Link log</a>
    </p>
  </div>
</div>
