import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { CarrierRegistrationService } from './carrier-registration.service';
import { SnackbarDefaultService } from 'src/app/shared/services/snackbar-default.service';
import { MatStepper } from '@angular/material/stepper';
import {
  certificacao_options,
  produto_options,
  veiculo_options,
  UFs,
} from 'src/app/shared/const/consts';
import { first } from 'rxjs/operators';
import { NgSelectComponent } from '@ng-select/ng-select';
interface ViaCepResponse {
  bairro: string;
  cep: string;
  complemento: string;
  ddd: string;
  gia: string;
  ibge: string;
  localidade: string;
  logradouro: string;
  siafi: string;
  uf: string;
}

@Component({
  selector: 'app-carrier-registration',
  templateUrl: './carrier-registration.component.html',
  styleUrls: ['./carrier-registration.component.scss'],
})
export class CarrierRegistrationComponent {
  userForm: FormGroup;
  companyForm: FormGroup;
  formEnded: boolean = false;

  resultField: boolean = false;
  resultDocument: boolean = false;

  validEmail: boolean = false;
  confirmPasswordValue: boolean = false;
  hash: string = '';
  clienteId: string = '';
  transportadoraId: string = '';
  @ViewChild(MatStepper) stepper!: MatStepper;
  logo: string = '../../../assets/img/svg/caminhao.svg';
  invalidZipCode: boolean = false;

  loadTypes = [
    { label: 'Lotação', value: 'LOTACAO' },
    { label: 'Fracionado', value: 'FRACIONADO' },
  ];
  titleConclusion: string = '';
  bodyConclusion: string = '';
  visibleStep: boolean = true;
  file: any = null;

  productTypes = produto_options;

  certifies = certificacao_options;

  UFs = UFs;

  vehicleTypes = veiculo_options

  emptyPassword: boolean = true;

  validLength: boolean = false;
  validNumber: boolean = false;
  validSpecialCharacter: boolean = false;
  validUpper: boolean = false;
  validLower: boolean = false;

  @ViewChild('nameRef') nameRef: ElementRef;
  @ViewChild('emailRef') emailRef: ElementRef;
  @ViewChild('passwordRef') passwordRef: ElementRef;
  @ViewChild('confirmPasswordRef') confirmPasswordRef: ElementRef;

  @ViewChild('documentRef') documentRef: ElementRef;
  @ViewChild('legalNameRef') legalNameRef: ElementRef;
  @ViewChild('zipcodeRef') zipcodeRef: ElementRef;
  @ViewChild('provinceRef') provinceRef: NgSelectComponent;
  @ViewChild('cityRef') cityRef: ElementRef;
  @ViewChild('streetRef') streetRef: ElementRef;
  @ViewChild('numberRef') numberRef: ElementRef;
  @ViewChild('neighborhoodRef') neighborhoodRef: ElementRef;
  @ViewChild('loadsTypeRef') loadsTypeRef: NgSelectComponent;
  @ViewChild('statesRef') statesRef: NgSelectComponent;
  @ViewChild('vehicleTypesRef') vehicleTypesRef: NgSelectComponent;
  @ViewChild('productTypeRef') productTypeRef: NgSelectComponent;
  @ViewChild('hasTrackerYesRef') hasTrackerYesRef: ElementRef;
  @ViewChild('hasTrackerNoRef') hasTrackerNoRef: ElementRef;
  @ViewChild('autonomousYesRef') autonomousYesRef: ElementRef;
  @ViewChild('autonomousNoRef') autonomousNoRef: ElementRef;

  constructor(
    private fb: FormBuilder,
    private http: HttpClient,
    private route: ActivatedRoute,
    private service: CarrierRegistrationService,
    private _snackBar: SnackbarDefaultService
  ) {
    this.userForm = this.fb.group({
      name: ['', [Validators.required]],
      email: ['', Validators.compose([Validators.required, Validators.email])],
      phonenumber: [''],
      password: ['', [Validators.required]],
      confirmPassword: ['', [Validators.required]],
    });

    this.companyForm = this.fb.group({
      document: ['', [Validators.required]],
      legalName: ['', [Validators.required]],
      zipcode: ['', [Validators.required]],
      province: ['', [Validators.required]],
      city: ['', [Validators.required]],
      street: ['', [Validators.required]],
      number: ['', [Validators.required]],
      neighborhood: ['', [Validators.required]],
      complement: [''],

      loadsType: ['', [Validators.required]],
      states: ['', [Validators.required]],
      vehicleTypes: ['', [Validators.required]],
      insurance: [''],
      patrimonialCost: [''],
      productType: ['', [Validators.required]],
      certifies: [''],
      hasTracker: ['', [Validators.required]],
      autonomous: ['', [Validators.required]],
    });

    this.userForm
      .get('password')
      ?.valueChanges.subscribe((value) => this.validFormatPassword(value));

    this.userForm
      .get('confirmPassword')
      ?.valueChanges.subscribe((value) => this.validConfirmPassword(value));
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      this.hash = params['hash'];
      this.clienteId = params['c'];
      this.transportadoraId = params['t'];
    });
  }

  onZipcodeChange(value: string) {
    let el = document.getElementById('input-zipcode');
    if (value) {
      const protocol = location.protocol;
      let zipsub = this.http
        .get<ViaCepResponse>(`${protocol}//viacep.com.br/ws/${value}/json/`)
        .subscribe((zipData) => {
          if (!zipData.cep) {
            this.invalidZipCode = true;
            if (el.classList.contains('is-valid')) {
              el.classList.remove('is-valid');
              el.classList.add('is-invalid');
            } else {
              el.classList.add('is-invalid');
            }
            this.companyForm.patchValue({
              province: '',
              city: '',
              street: '',
              complement: '',
              neighborhood: '',
            });
            zipsub.unsubscribe();
          } else {
            this.invalidZipCode = false;
            if (el.classList.contains('is-invalid')) {
              el.classList.remove('is-valid');
              el.classList.add('is-valid');
            } else {
              el.classList.add('is-valid');
            }
            this.companyForm.patchValue({
              province: zipData.uf,
              city: zipData.localidade,
              street: zipData.logradouro,
              complement: zipData.complemento,
              neighborhood: zipData.bairro,
            });
            zipsub.unsubscribe();
          }
        });
    }
  }

  visiblePassword(event: any, elementId: string) {
    const { target } = event;
    const { checked } = target;
    const element = document.getElementById(elementId);
    element?.setAttribute('type', checked ? 'text' : 'password');
  }

  public checkEmail() {
    this.validEmail = false;
    const email = this.userForm.get('email')?.value;

    if (email.match(/[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,}$/)) {
      this.validEmail = true;
    } else {
      this.validEmail = false;
    }
    return this.validEmail;
  }

  validConfirmPassword(value: string) {
    const password = this.userForm.get('password')?.value;
    const confirmPassword = value;

    if (confirmPassword === password) {
      this.confirmPasswordValue = true;
      this.userForm.get('confirmPassword')?.setErrors(null);
    } else {
      this.confirmPasswordValue = false;
      this.userForm.get('confirmPassword')?.setErrors({ invalidConfirm: true });
    }
  }

  submitUserData() {
    this.userForm.markAllAsTouched();
    if (!this.userForm.valid) {
      this._snackBar.openSnackBarWarning('Há campos que necessitam de atenção');
      if(this.userForm.controls['name'].invalid){
        this.nameRef.nativeElement.focus();
      } else if(this.userForm.controls['email'].invalid){
        this.emailRef.nativeElement.focus();
      } else if(this.userForm.controls['password'].invalid){
        this.passwordRef.nativeElement.focus();
      } else if(this.userForm.controls['confirmPassword'].invalid){
        this.confirmPasswordRef.nativeElement.focus();
      }
      return;
    }
    const passValue = this.userForm.controls['password'].value;
    const cpassValue = this.userForm.controls['confirmPassword'].value;
    if (passValue !== cpassValue) {
      return;
    }
  }

  submitFormData() {
    this.companyForm.markAllAsTouched();

    if(this.companyForm.invalid){  
      this._snackBar.openSnackBarWarning('Há campos que necessitam de atenção');
      if(this.companyForm.controls['document'].invalid){
        this.documentRef.nativeElement.focus();
      } else if(this.companyForm.controls['legalName'].invalid){
        this.legalNameRef.nativeElement.focus();
      } else if(this.companyForm.controls['zipcode'].invalid){
        this.zipcodeRef.nativeElement.focus();
      } else if(this.companyForm.controls['province'].invalid){
        this.provinceRef.focus();
      } else if(this.companyForm.controls['city'].invalid){
        this.cityRef.nativeElement.focus();
      } else if(this.companyForm.controls['street'].invalid){
        this.streetRef.nativeElement.focus();
      } else if(this.companyForm.controls['number'].invalid){
        this.numberRef.nativeElement.focus();
      } else if(this.companyForm.controls['neighborhood'].invalid){
        this.neighborhoodRef.nativeElement.focus();
      } else if(this.companyForm.controls['loadsType'].invalid){
        this.loadsTypeRef.focus();
      } else if(this.companyForm.controls['states'].invalid){
        this.statesRef.focus();
      } else if(this.companyForm.controls['vehicleTypes'].invalid){
        this.vehicleTypesRef.focus();
      } else if(this.companyForm.controls['productType'].invalid){
        this.productTypeRef.focus();
      }else if(this.companyForm.controls['hasTracker'].invalid){
        this.hasTrackerYesRef.nativeElement.focus();
      }else if(this.companyForm.controls['autonomous'].invalid){
        this.autonomousYesRef.nativeElement.focus();
      }
      return;
    } 

      let transportadora = {
        cnpj: this.companyForm.value.document,
        razaoSocial: this.companyForm.value.legalName,
        veiculosComRastreador: this.companyForm.value.hasTracker,
        autonomo: this.companyForm.value.autonomous,
        valorPatrimonial: this.companyForm.value.patrimonialCost,
        numeroSeguro: this.companyForm.value.insurance,
        carga: this.companyForm.value.loadsType,
        ufAtendida: this.companyForm.value.states,
        veiculo: this.companyForm.value.vehicleTypes,
        certificacao: this.companyForm.value.certifies
          ? this.companyForm.value.certifies.map((c: any) => {
              return {
                certificacao: c,
              };
            })
          : null,
        produto: this.companyForm.value.productType,
        endereco: {
          uf: this.companyForm.value.province,
          cidade: this.companyForm.value.city,
          cep: this.companyForm.value.zipcode,
          logradouro: this.companyForm.value.street,
          numero: this.companyForm.value.number,
          complemento: this.companyForm.value.complement,
          bairro: this.companyForm.value.neighborhood,
        },
        usuario: {
          nome: this.userForm.get('name')?.value,
          email: this.userForm.get('email')?.value,
          senha: this.userForm.get('password')?.value,
          telefone: this.userForm.get('phonenumber')?.value,
          tipoUsuario: 1,
          status: 'INATIVO',
        },
      };
      this.service
        .registerCarrier(
          transportadora,
          this.hash,
          this.clienteId,
          this.transportadoraId,
          this.file
        )
        .pipe(first())
        .subscribe({
          next: (result: any) => {
            this.titleConclusion = 'Cadastro concluído';
            this.bodyConclusion =
              'Dentro de alguns instantes, você receberá um link para ativar seu cadastro. Para acessar a plataforma confirme o seu e-mail clicando no link enviado, antes de realizar o login!!';
          },
          error: (error) => {
            this.visibleStep = false;
            if(error.error == 'Já existe um empresa com esse cnpj'){
              this._snackBar.openSnackBarInfo(
                `Atenção! Já existe empresa com esse CNPJ. `,

              );
            } else if(error.error == 'Já existe um usuário com esse email'){
                this._snackBar.openSnackBarInfo(
                  `Atenção! Já existe usuário com esse email. `,
                );
            } else {
              this._snackBar.openSnackBarError('Seu cadastro não foi concluído');
            }
            this.titleConclusion = 'Seu cadastro não foi concluído';
            this.bodyConclusion =
              'Ops, ocorreu algum problema na criação do seu usuário, verifique a mensagem, faça as alterações necessárias e tente novamente!';
            }
            }
        );

      this.formEnded = true;
  }

  onStepChange(event: any) {
    if (event.selectedIndex != 2) {
      this.titleConclusion = '';
      this.bodyConclusion = '';
      const stepToBlock = this.stepper._steps.toArray()[2];
      stepToBlock.completed = false;
      stepToBlock.editable = false;
      stepToBlock.interacted = false;
      // this.stepper.selectedIndex = 0;
    }
  }

  updatePhoto(event: any) {
    let img = document.createElement('img');
    let urlImage = URL.createObjectURL(event.target.files[0]);
    this.file = event.target.files[0];
    let width = 0;
    let height = 0;
    img.src = urlImage;
    img.onload = () => {
      width = img.naturalWidth;
      height = img.naturalHeight;

      if (width > 400 || height > 400) {
        this._snackBar.openSnackBarInfo(
          'Imagem com dimensões fora dos padrões aceitos'
        );
      } else {
        this.logo = urlImage;
      }
    };
  }

  public validFormatPassword(password: string) {
    let confirmPassword = this.userForm.get('confirmPassword')?.value
    if (password == null || password === '') {
      this.validLength = false;
      this.validNumber = false;
      this.validSpecialCharacter = false;
      this.validUpper = false;
      this.validLower = false;
      return;
    }

    this.emptyPassword = false;

    this.validLength = password.length >= 8 && password.length <= 30;
    this.validNumber = !!RegExp(/\d/).exec(password);
    this.validSpecialCharacter = !!password.match(/[^a-zA-Z 0-9]+/g);
    this.validUpper = !!RegExp(/[A-Z]+/).exec(password);
    this.validLower = !!RegExp(/[a-z]+/).exec(password);

    if(password != null && confirmPassword != null){
      if(password == confirmPassword) this.confirmPasswordValue = true
    }
  }
}
