<div class="border-0">
  <mat-card class="container-company card-container">
    <mat-card-content>
      <h6>Cadastro de empresa</h6>
      <mat-tab-group
        mat-stretch-tabs="false"
        mat-align-tabs="start"
        class="mt-4"
        dynamicHeight
        #tabGroup
      >
        <mat-tab label="Informações gerais" class="mt-3">
          <app-general-information></app-general-information>
        </mat-tab>

        <mat-tab label="Cadastro de requisitos" class="mt-3">
          <app-requirements-register></app-requirements-register>
        </mat-tab>

        <mat-tab label="Certificações" class="mt-3">
          <app-certifications></app-certifications>
        </mat-tab>
      </mat-tab-group>
    </mat-card-content>
  </mat-card>
</div>
