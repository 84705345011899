import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatStepperModule } from '@angular/material/stepper';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatDialogModule } from '@angular/material/dialog';

import { NgSelectModule } from '@ng-select/ng-select';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';

import { LoginComponent } from './login/login.component';
import { CarrierRegistrationComponent } from './carrier-registration/carrier-registration.component';
import { AuthenticationRoutingModule } from './authentication.routing';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatCardModule } from '@angular/material/card';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { RedefinePasswordComponent } from './redefine-password/redefine-password.component';
import { UserActivationComponent } from './user-activation/user-activation.component';
import { PartnerInvitationComponent } from './partner-invitation/partner-invitation.component';
import { LandingHomeComponent } from './landing-home/landing-home.component';
import { LandingEmbarcadorComponent } from './landing-embarcador/landing-embarcador.component';
import { LandingTransportadorComponent } from './landing-transportador/landing-transportador.component';
import { MatTooltipModule } from '@angular/material/tooltip';

@NgModule({
  declarations: [
    LoginComponent,
    CarrierRegistrationComponent,
    ForgotPasswordComponent,
    RedefinePasswordComponent,
    UserActivationComponent,
    PartnerInvitationComponent,
    LandingHomeComponent,
    LandingEmbarcadorComponent,
    LandingTransportadorComponent,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    HttpClientModule,
    AuthenticationRoutingModule,
    MatButtonModule,
    MatCheckboxModule,
    MatInputModule,
    MatFormFieldModule,
    MatIconModule,
    MatStepperModule,
    NgSelectModule,
    NgxMaskDirective,
    MatSnackBarModule,
    MatCardModule,
    MatDialogModule,
    NgxMaskPipe,
    FlexLayoutModule,
    MatTooltipModule,
  ],
  providers: [provideNgxMask()],
})
export class AuthenticationModule {}
