import { SupplierDetailsComponent } from './supplier-details/supplier-details.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MyProfileComponent } from './my-profile/my-profile.component';
import { MyCompaniesComponent } from './my-companies/my-companies.component';
import { MyCompanyFormComponent } from './my-company-form/my-company-form.component';
import { MyUsersComponent } from './my-users/my-users.component';
import { MyUserFormComponent } from './my-user-form/my-user-form.component';
import { UserTypeComponent } from './user-type/user-type.component';
import { IntegrationsComponent } from './integrations/integrations.component';
import { MyRecipientComponent } from './my-recipient/my-recipient.component';
import { RecipientComponent } from './recipient/recipient.component';
import { RecipientDetailsComponent } from './recipient-details/recipient-details.component';
import { OfferContractComponent } from './offer-contract/offer-contract.component';
import { MySuppliersComponent } from './my-suppliers/my-suppliers.component';
import { MySupplierFormComponent } from './my-supplier-form/my-supplier-form.component';
import { MyAccountComponent } from './my-account/my-account.component';
import { EnvironmentRulesComponent } from './environment-rules/environment-rules.component';

const routes: Routes = [
  {
    path: '',
    component: MyProfileComponent,
  },
  {
    path: 'profile',
    component: MyProfileComponent,
  },
  {
    path: 'my-account',
    component: MyAccountComponent,
  },
  {
    path: 'companies',
    component: MyCompaniesComponent,
  },
  {
    path: 'companies/new-company',
    component: MyCompanyFormComponent,
  },
  {
    path: 'companies/edit-company/:id',
    component: MyCompanyFormComponent,
  },
  {
    path: 'suppliers',
    component: MySuppliersComponent,
  },
  {
    path:'suppliers/supplier-details/:id',
    component: SupplierDetailsComponent

  },
  {
    path: 'suppliers/new-supplier',
    component: MySupplierFormComponent,
  },
  {
    path: 'suppliers/edit-supplier/:id',
    component: MySupplierFormComponent,
  },
  {
    path: 'users',
    component: MyUsersComponent,
  },
  {
    path: 'users/new-user',
    component: MyUserFormComponent,
  },
  {
    path: 'users/edit-user/:id',
    component: MyUserFormComponent,
  },
  {
    path: 'user-types',
    component: UserTypeComponent,
  },
  {
    path: 'integrations',
    component: IntegrationsComponent,
  },
  {
    path: 'offer-contract',
    component: OfferContractComponent,
  },
  {
    path:'recipients',
    component: MyRecipientComponent
  },
  {
    path:'recipients/details/:id',
    component: RecipientDetailsComponent

  },
  {
    path: 'recipients/new-recipient',
    component: RecipientComponent,
  },
  {
    path: 'recipients/edit-recipient/:id',
    component: RecipientComponent,
  },
  {
    path: 'environment-rules',
    component: EnvironmentRulesComponent
  },
  
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ConfigurationsShipperRoutingModule {}
