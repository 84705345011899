<div class="container-fluid mt-5">
  <div class="row justify-content-center" *ngIf="!formEnded">
    <div class="col-md-4 text-center">
      <h4>Cadastre sua conta Transportador</h4>
      <img src="/assets/img/svg/Registration.svg" alt="" />
    </div>
  </div>
  <div class="row justify-content-center">
    <div class="col-md-11">
      <mat-stepper linear #stepper (selectionChange)="onStepChange($event)">
        <mat-step [stepControl]="userForm">
          <form
            [formGroup]="userForm"
          >
            <ng-template matStepLabel>Cadastro de usuário</ng-template>
            <div class="card-body card-container"  style="margin-top: 1rem;">
              <div class="row mb-2">
                <h5>Cadastro de usuário</h5>
              </div>
              <div class="row mb-2">
                <div class="col col-md-6 form-group" fxFlex="100">
                  <label class="label-input form-label required" for="name" id="label-username">
                    Nome de usuário
                  </label>
                  <input
                    #nameRef
                    id="input-username"
                    matInput
                    name="name"
                    class="form-control input-data w-100"
                    formControlName="name"
                    placeholder="Digite seu nome"
                    required
                    [ngClass]="{
                      'is-invalid':
                        userForm.get('name')?.invalid && userForm.get('name')?.touched,
                      'is-valid':
                      userForm.get('name')?.value?.length> 0
                    }"
                  />
                  <div
                    *ngIf="userForm.get('name')?.invalid && userForm.get('name')?.touched"
                    class="invalid-feedback label-mandatory"
                  >
                    Campo obrigatório*
                  </div>
                </div>
              </div>
              <div class="row mb-2">
                <div class="col col-md-6 form-group">
                  <label class="label-input form-label required" for="email"id="label-email" >
                    Email
                  </label>
                  <input
                    #emailRef
                    id="input-email"
                    matInput
                    name="email"
                    type="email"
                    class="form-control input-data w-100"
                    formControlName="email"
                    placeholder="Digite seu email"
                    required
                    [ngClass]="{
                      'is-invalid':
                        userForm.get('email')?.invalid &&
                        userForm.get('email')?.touched,
                      'is-valid':
                        checkEmail() &&
                        userForm.get('email')?.valid &&
                        userForm.get('email')?.touched &&
                        userForm.get('email')?.value.length > 0
                    }"
                  />
                  <div
                    *ngIf="
                      userForm.get('email')?.invalid &&
                      userForm.get('email')?.touched &&
                      userForm.get('email')?.value.length == 0
                    "
                    class="invalid-feedback label-mandatory"
                  >
                    Campo obrigatório*
                  </div>
                  <div
                    *ngIf="
                      checkEmail() === false &&
                      userForm.get('email')?.value.length > 0 &&
                      userForm.get('email')?.errors
                    "
                    class="invalid-feedback label-mandatory"
                  >
                    Email inválido!*
                  </div>
                </div>
                <div class="col col-md-6 form-group">
                  <label class="label-input form-label" for="phonenumber"id="label-phone" >
                    Telefone
                  </label>
                  <input
                    id="input-phone"
                    matInput
                    mask="(00) 0000-0000||(00) 00000-0000"
                    name="phonenumber"
                    class="form-control input-data w-100"
                    formControlName="phonenumber"
                    placeholder="Digite seu telefone"
                    [ngClass]="{
                      'is-invalid':
                      userForm.get('phonenumber')?.invalid && userForm.get('phonenumber')?.touched && userForm.get('phonenumber')?.value?.length > 0,
                      'is-valid':
                      userForm.get('phonenumber')?.valid &&
                      userForm.get('phonenumber')?.touched && userForm.get('phonenumber')?.value?.length > 0 ,
                    }"
                  />
                  <div
                    *ngIf="
                      userForm.get('phonenumber')?.invalid &&
                      userForm.get('phonenumber')?.value.length > 0
                    "
                    class="invalid-feedback label-mandatory"
                  >
                    Telefone inválido!
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col col-md-6 form-group position-relative">
                  <label class="label-input form-label required" for="password" id="label-password">
                    Senha
                  </label>
                  <input
                    #passwordRef
                    matInput
                    id="input-password"
                    name="password"
                    type="password"
                    class="form-control icon input-data w-100"
                    formControlName="password"
                    placeholder="Digite uma senha"
                    required
                    [ngClass]="{
                      'is-invalid':
                        userForm.get('password')?.invalid && userForm.get('password')?.touched || ((!validLength || !validUpper || !validLower || !validNumber || !validSpecialCharacter) && userForm.get('password')?.value?.length > 0) ,
                      'is-valid':
                      ((validLength && validUpper && validLower && validNumber && validSpecialCharacter) && userForm.get('password')?.value?.length > 0)
                    }"
                  />
                  <div
                    *ngIf="userForm.get('password')?.invalid && userForm.get('password')?.value?.length == 0"
                    class="invalid-feedback label-mandatory"
                  >
                    Campo obrigatório*
                  </div>
                  <i
                    class="ri-lock-line password-icon position-absolute end-75 mx-2 py-1"
                  ></i>
                </div>
                <div class="col col-md-6 form-group position-relative">
                  <label
                    class="label-input form-label required"
                    for="confirmPassword"
                    id="label-confirm-password"
                  >
                    Confirme a sua senha
                  </label>
                  <input
                    #confirmPasswordRef
                    matInput
                    id="input-confirm-password"
                    name="confirmPassword"
                    type="password"
                    class="form-control icon input-data w-100"
                    formControlName="confirmPassword"
                    placeholder="Confirme sua senha"
                    required
                    [ngClass]="{
                      'is-invalid':
                        (userForm.get('confirmPassword')?.invalid &&
                        userForm.get('confirmPassword')?.touched) || (userForm.get('confirmPassword')?.value?.length > 0 && !confirmPasswordValue),
                      'is-valid':
                      userForm.get('confirmPassword')?.value?.length > 0 && confirmPasswordValue
                    }"
                  />
                  <div
                    *ngIf="
                      userForm.get('confirmPassword')?.invalid &&  userForm.get('confirmPassword')?.value?.length == 0
                    "
                    class="invalid-feedback label-mandatory"
                  >
                    Campo obrigatório*
                  </div>
                  <div
                    *ngIf="userForm.get('confirmPassword')?.value?.length > 0 && !confirmPasswordValue"
                    class="invalid-feedback label-mandatory"
                  >
                    Confirmação de senha incorreta*
                  </div>
                  <i
                    class="ri-lock-line password-icon position-absolute end-75 mx-2 py-1"
                  ></i>
                </div>
              </div>
              <div class="row mb-2">
                <div class="col col-md-6">
                  <div class="form-check">
                    <input
                      id="check-visible-password"
                      name="showPassword"
                      type="checkbox"
                      class="form-check-input"
                      (change)="visiblePassword($event, 'input-password')"
                    />
                    <label
                      class="form-check-label view-password mt-1"
                      for="showPassword"
                    >
                      Mostrar senha
                    </label>
                  </div>
                </div>
                <div class="col col-md-6">
                  <div class="form-check">
                    <input
                      id="check-visible-confirm-password"
                      name="showConfirmPassword"
                      type="checkbox"
                      class="form-check-input"
                      (change)="visiblePassword($event, 'input-confirm-password')"
                    />
                    <label
                      class="form-check-label view-password mt-1"
                      for="showConfirmPassword"
                    >
                      Mostrar senha
                    </label>
                  </div>
                </div>
              </div>

              <div class="container-passwordvalidate">
                <p class="text-password">Sua senha deve conter:</p>

                <div>
                  <p id="check-length-characters">
                    <i
                      *ngIf="emptyPassword || (!emptyPassword && validLength)"
                      class="ri-checkbox-circle-fill icon-check"
                      [ngClass]="{
                        'check-none': emptyPassword,
                        'check-yes': !emptyPassword && validLength
                      }"
                    ></i
                    ><i
                      class="ri-close-circle-fill icon-check check-no"
                      *ngIf="!emptyPassword && !validLength"
                    ></i
                    >Entre 8 e 30 caracteres
                  </p>
                  <p id="check-uppercase-lowercase">
                    <i
                      *ngIf="
                        emptyPassword ||
                        (!emptyPassword && validUpper && validLower)
                      "
                      class="ri-checkbox-circle-fill icon-check"
                      [ngClass]="{
                        'check-none': emptyPassword,
                        'check-yes': !emptyPassword && validUpper && validLower
                      }"
                    ></i
                    ><i
                      class="ri-close-circle-fill icon-check check-no"
                      *ngIf="!emptyPassword && (!validUpper || !validLower)"
                    ></i
                    >Letras maiúsculas e minúsculas
                  </p>
                  <p id="check-number">
                    <i
                      *ngIf="emptyPassword || (!emptyPassword && validNumber)"
                      class="ri-checkbox-circle-fill icon-check"
                      [ngClass]="{
                        'check-none': emptyPassword,
                        'check-yes': !emptyPassword && validNumber
                      }"
                    ></i
                    ><i
                      class="ri-close-circle-fill icon-check check-no"
                      *ngIf="!emptyPassword && !validNumber"
                    ></i
                    >Pelo menos um número
                  </p>
                  <p id="check-special-character">
                    <i
                      *ngIf="
                        emptyPassword ||
                        (!emptyPassword && validSpecialCharacter)
                      "
                      class="ri-checkbox-circle-fill icon-check"
                      [ngClass]="{
                        'check-none': emptyPassword,
                        'check-yes': !emptyPassword && validSpecialCharacter
                      }"
                    ></i
                    ><i
                      class="ri-close-circle-fill icon-check check-no"
                      *ngIf="!emptyPassword && !validSpecialCharacter"
                    ></i
                    >Pelo menos um caractere especial
                  </p>
                </div>
              </div>

              <div class="row justify-content-end">
                <div class="col col-md-2 text-end">
                  <button
                    id="btn-prox-stepper-1"
                    mat-flat-button
                    matStepperNext
                    class="btn-green"
                    (click)="submitUserData()"
                  >
                    Próximo
                  </button>
                </div>
              </div>
            </div>
          </form>
        </mat-step>
        <mat-step [stepControl]="companyForm">
          <form [formGroup]="companyForm">
            <ng-template matStepLabel>Cadastro de empresa</ng-template>
            <div class="card-body card-container"  style="margin-top: 1rem;">
              <div class="row mb-2">
                <h5>Cadastro da empresa matriz</h5>
              </div>
              <div class="row mb-2">
                <div fxLayout="row" class="mt-4 mb-4">
                  <div fxLayout="column">
                    <div style="display: flex">
                      <img
                        src="{{ logo }}"
                        alt="Imagem Indisponível"
                        id="logo"
                        width="88px"
                        height="88px"
                        [ngClass]="'logo'"
                      />
                      <label
                        id="label-file"
                        for="input-file"
                        class="btn-photo"
                        matTooltip="Somente imagens com tamanho de até 400 x 400 serão aceitas"
                        ><i class="ri-camera-fill icon-photo"></i
                      ></label>
                      <input
                        type="file"
                        id="input-file"
                        accept="image/jpeg, image/png, image/jpg"
                        name="logo"
                        (change)="updatePhoto($event)"
                      />
                    </div>
                  </div>
                </div>

                <div class="col col-md-6 form-group position-relative">
                  <label class="label-input form-label required" for="document" id="label-document">
                    CNPJ
                  </label>
                  <input
                    #documentRef
                    i="input-document"
                    matInput
                    mask="00.000.000/0000-00"
                    name="document"
                    class="form-control input-data w-100"
                    formControlName="document"
                    placeholder="Digite o CNPJ"
                    required
                    [ngClass]="{
                      'is-invalid':
                      companyForm.get('document')?.invalid &&
                      companyForm.get('document')?.touched,
                      'is-valid':
                      companyForm.get('document')?.valid &&
                      companyForm.get('document')?.touched,
                    }"
                  />
                  <div
                    *ngIf="
                      companyForm.get('document')?.invalid &&
                      companyForm.get('document')?.touched &&
                      companyForm.get('document').value.length == 0
                    "
                    class="invalid-feedback label-mandatory"
                  >
                    Campo obrigatório*
                  </div>
                  <div
                    *ngIf="
                      companyForm.get('document').value.length > 0 &&
                      companyForm.get('document').value.length < 14
                    "
                    class="invalid-feedback label-mandatory"
                  >
                    CNPJ inválido!
                  </div>
                </div>
                <div class="col col-md-6 form-group">
                  <label
                    class="label-input form-label required"
                    for="legalName"
                    id="label-legal-name"
                  >
                    Razão social
                  </label>
                  <input
                    #legalNameRef
                    id="input-legal-name"
                    matInput
                    name="legalName"
                    class="form-control input-data w-100"
                    formControlName="legalName"
                    placeholder="Digite a razão social"
                    required
                    [ngClass]="{
                      'is-invalid':
                        companyForm.get('legalName')?.invalid && companyForm.get('legalName')?.touched,
                      'is-valid':
                      companyForm.get('legalName')?.value?.length > 0
                    }"
                  />
                  <div
                    *ngIf="
                      companyForm.get('legalName')?.invalid && companyForm.get('legalName')?.value?.length == 0
                    "
                    class="invalid-feedback label-mandatory"
                  >
                    Campo obrigatório*
                  </div>
                </div>
              </div>
              <div class="row mb-2">
                <div class="col col-md-6 form-group">
                  <label class="label-input form-label required" for="zipcode" id="label-zipcode">
                    CEP
                  </label>
                  <input
                    #zipcodeRef
                    id="input-zipcode"
                    matInput
                    mask="00000-000"
                    name="zipcode"
                    class="form-control input-data w-100"
                    formControlName="zipcode"
                    placeholder="Digite o CEP"
                    required
                    (blur)="onZipcodeChange(companyForm.get('zipcode')?.value)"
                    [ngClass]="{
                      'is-invalid':
                      companyForm.get('zipcode')?.invalid &&
                      companyForm.get('zipcode')?.touched,
                      'is-valid':
                      companyForm.get('zipcode')?.value.length == 8 && !invalidZipCode,
                    }"
                  />
                  <div
                    *ngIf="
                      (companyForm.get('zipcode')?.touched &&
                        companyForm.get('zipcode')?.errors) ||
                      invalidZipCode
                    "
                    class="invalid-feedback label-mandatory"
                  >
                    <span *ngIf="companyForm.get('zipcode')?.value?.length == 0">
                      Campo obrigatório*</span
                    >
                    <span
                      *ngIf="
                        companyForm.get('zipcode')?.value?.length > 0 &&
                        invalidZipCode
                      "
                    >
                      CEP inválido!
                    </span>
                  </div>
                </div>
              </div>
              <div class="row mb-2">
                <div class="col col-md-6 form-group">
                  <label class="label-input form-label required" for="province" id="province">
                    Estado
                  </label>
                  <ng-select
                    #provinceRef
                    id="select-province"
                    name="province"
                    formControlName="province"
                    [items]="UFs"
                    [closeOnSelect]="true"
                    [searchable]="true"
                    [clearable]="false"
                    bindLabel="value"
                    bindValue="value"
                    required
                    [ngClass]="{
                      'invalid is-invalid':
                        companyForm.get('province')?.invalid && companyForm.get('province')?.touched,
                      'valid is-valid':
                        companyForm.get('province')?.value?.length > 0
                    }"
                  >
                    <ng-template
                      ng-label-tmp
                      let-item="item"
                      let-index="index"
                      let-search="searchTerm"
                    >
                      {{ item.value }}
                    </ng-template>
                  </ng-select>
                  <div
                    *ngIf="
                     companyForm.get('province')?.invalid && companyForm.get('province')?.value?.length == 0
                    "
                    class="invalid-feedback label-mandatory"
                  >
                    Campo obrigatório*
                  </div>
                </div>
                <div class="col col-md-6 form-group">
                  <label class="label-input form-label required" for="city" id="label-city">
                    Cidade
                  </label>
                  <input
                    #cityRef
                    id="input-city"
                    matInput
                    name="city"
                    class="form-control input-data w-100"
                    formControlName="city"
                    placeholder="Digite o nome da cidade"
                    required
                    [ngClass]="{
                      'is-invalid':
                        companyForm.get('city')?.invalid && companyForm.get('city')?.touched,
                      'is-valid':
                        companyForm.get('city')?.value?.length > 0
                    }"
                  />
                  <div
                    *ngIf="companyForm.get('city')?.invalid && companyForm.get('city')?.value?.length == 0"
                    class="invalid-feedback label-mandatory"
                  >
                    Campo obrigatório*
                  </div>
                </div>
              </div>
              <div class="row mb-2">
                <div class="col col-md-6 form-group">
                  <label class="label-input form-label required" for="street" id="label-street">
                    Logradouro
                  </label>
                  <input
                    #streetRef
                    id="input-street"
                    matInput
                    name="street"
                    class="form-control input-data w-100"
                    formControlName="street"
                    placeholder="Digite o logradouro"
                    required
                    [ngClass]="{
                      'is-invalid':
                        companyForm.get('street')?.invalid && companyForm.get('street')?.touched,
                      'is-valid':
                        companyForm.get('street')?.value?.length > 0
                    }"
                  />
                  <div
                    *ngIf="companyForm.get('street')?.invalid && companyForm.get('street')?.value?.length == 0" 
                    class="invalid-feedback label-mandatory"
                  >
                    Campo obrigatório*
                  </div>
                </div>
                <div class="col col-md-6 form-group">
                  <label class="label-input form-label required" for="number" id="number">
                    Número
                  </label>
                  <input
                    #numberRef
                    id="input-number"
                    matInput
                    name="number"
                    class="form-control input-data w-100"
                    formControlName="number"
                    placeholder="Digite o número"
                    required
                    [ngClass]="{
                      'is-invalid':
                        companyForm.get('number')?.invalid && companyForm.get('number')?.touched,
                      'is-valid':
                        companyForm.get('number')?.value?.length > 0
                    }"
                  />
                  <div
                    *ngIf="companyForm.get('number')?.invalid && companyForm.get('number')?.value?.length == 0"
                    class="invalid-feedback label-mandatory"
                  >
                    Campo obrigatório*
                  </div>
                </div>
              </div>
              <div class="row mb-2">
                <div class="col col-md-6 form-group">
                  <label
                    class="label-input form-label required"
                    for="neighborhood"
                    id="label-neighborhood"
                  >
                    Bairro
                  </label>
                  <input
                    #neighborhoodRef
                    id="input-neighborhood"
                    matInput
                    name="neighborhood"
                    class="form-control input-data w-100"
                    formControlName="neighborhood"
                    placeholder="Digite o bairro"
                    required
                    [ngClass]="{
                      'is-invalid':
                        companyForm.get('neighborhood')?.invalid &&
                        companyForm.get('neighborhood')?.touched,
                      'is-valid':
                        companyForm.get('neighborhood')?.value?.length > 0
                    }"
                  />
                  <div
                    *ngIf="
                      companyForm.get('neighborhood')?.invalid &&
                      companyForm.get('neighborhood')?.value?.length == 0
                    "
                    class="invalid-feedback label-mandatory"
                  >
                    Campo obrigatório*
                  </div>
                </div>
                <div class="col col-md-6 form-group">
                  <label class="label-input form-label" for="complement" id="label-complement">
                    Complemento
                  </label>
                  <input
                    id="input-complement"
                    matInput
                    name="complement"
                    class="form-control input-data w-100"
                    formControlName="complement"
                    placeholder="Digite um complemento"
                    [ngClass]="{
                      'is-valid':
                        companyForm.get('complement')?.value?.length > 0 
                    }"
                  />
                </div>
              </div>

              <div class="row mt-4">
                <h5>Informações específicas</h5>
              </div>

              <div class="row mb-2">
                <div class="col col-md-6 form-group position-relative">
                  <label
                    class="label-input form-label required"
                    for="loadsType"
                    id="label-loadsType"
                  >
                    Tipo de carga
                  </label>
                  <ng-select
                    #loadsTypeRef
                    id="select-loadsType"
                    name="loadsType"
                    formControlName="loadsType"
                    [items]="loadTypes"
                    [multiple]="true"
                    [closeOnSelect]="false"
                    [searchable]="true"
                    bindLabel="label"
                    bindValue="value"
                    required
                    [ngClass]="{
                      'invalid is-invalid':
                        companyForm.get('loadsType')?.invalid && companyForm.get('loadsType')?.touched,
                      'valid is-valid':
                        companyForm.get('loadsType')?.value?.length > 0
                    }"
                  ></ng-select>
                  <div
                    *ngIf="
                      companyForm.get('loadsType')?.invalid &&
                      companyForm.get('loadsType')?.value?.length == 0
                    "
                    class="invalid-feedback label-mandatory"
                  >
                    Campo obrigatório*
                  </div>
                </div>
                <div class="col col-md-6 form-group position-relative">
                  <label class="label-input form-label required" for="states" id="label-states">
                    UFs atendidas
                  </label>
                  <ng-select
                    #statesRef
                    id="select-states"
                    name="states"
                    formControlName="states"
                    [items]="UFs"
                    [multiple]="true"
                    bindLabel="value"
                    bindValue="value"
                    required
                    [ngClass]="{
                      'invalid is-invalid':
                        companyForm.get('states')?.invalid && companyForm.get('states')?.touched,
                      'valid is-valid':
                        companyForm.get('states')?.value?.length > 0
                    }"
                  >
                    <ng-template
                      ng-option-tmp
                      let-item="item"
                      let-index="index"
                      let-search="searchTerm"
                    >
                      {{ item.value }}
                    </ng-template>
                  </ng-select>
                  <div
                    *ngIf="
                      companyForm.get('states')?.invalid &&
                      companyForm.get('states')?.value?.length == 0"
                    class="invalid-feedback label-mandatory"
                  >
                    Campo obrigatório*
                  </div>
                </div>
              </div>
              <div class="row mb-2">
                <div class="col col-md-6 form-group position-relative">
                  <label
                    class="label-input form-label required"
                    for="vehicleTypes"
                    id="label-vehicleTypes"
                  >
                    Tipo de veículo
                  </label>
                  <ng-select
                    #vehicleTypesRef
                    id="select-vehicleTypes"
                    name="vehicleTypes"
                    formControlName="vehicleTypes"
                    [items]="vehicleTypes"
                    [multiple]="true"
                    bindLabel="label"
                    bindValue="value"
                    required
                    [ngClass]="{
                      'invalid is-invalid':
                        companyForm.get('vehicleTypes')?.invalid &&
                        companyForm.get('vehicleTypes')?.touched,
                      'valid is-valid':
                        companyForm.get('vehicleTypes')?.value?.length > 0
                    }"
                  ></ng-select>
                  <div
                    *ngIf="
                      companyForm.get('vehicleTypes')?.invalid &&
                      companyForm.get('vehicleTypes')?.value?.length == 0
                    "
                    class="invalid-feedback label-mandatory"
                  >
                    Campo obrigatório*
                  </div>
                </div>
                <div class="col col-md-6 form-group">
                  <label class="label-input form-label" for="insurance" id="label-insurance">
                    Número da apólice de seguro
                  </label>
                  <input
                    id="input-insurance"
                    matInput
                    name="insurance"
                    class="form-control input-data w-100"
                    formControlName="insurance"
                    placeholder="Digite o número da apólice de seguro"
                    [ngClass]="{
                      'is-valid':
                        companyForm.get('insurance')?.value?.length > 0
                    }"
                  />
                </div>
              </div>
              <div class="row mb-2">
                <div class="col col-md-6 form-group">
                  <label class="label-input form-label" for="patrimonialCost" id="label-patrimonialCost">
                    Valor patrimonial
                  </label>
                  <input
                    id="input-patrimonialCost"
                    matInput
                    prefix="R$ "
                    mask="separator.2"
                    thousandSeparator="."
                    name="patrimonialCost"
                    type="text"
                    class="form-control input-data w-100"
                    formControlName="patrimonialCost"
                    placeholder="Digite o valor patrimonial"
                    [ngClass]="{
                      'is-valid':
                        companyForm.get('patrimonialCost')?.value?.length > 0
                    }"
                  />
                </div>
                <div class="col col-md-6 form-group">
                  <label
                    class="label-input form-label required"
                    for="productType"
                    id="label-productType"
                  >
                    <span> Tipo de produto </span>
                  </label>
                  <ng-select
                    #productTypeRef
                    id="select-productType"
                    class="custom"
                    name="productType"
                    formControlName="productType"
                    [items]="productTypes"
                    [multiple]="true"
                    bindLabel="label"
                    bindValue="value"
                    required
                    [ngClass]="{
                      'invalid is-invalid':
                        companyForm.get('productType')?.invalid &&
                        companyForm.get('productType')?.touched,
                      'valid is-valid':
                        companyForm.get('productType')?.value?.length > 0
                    }"
                  ></ng-select>
                  <div
                    *ngIf="
                      companyForm.get('productType')?.invalid &&
                      companyForm.get('productType')?.value?.length == 0
                    "
                    class="invalid-feedback label-mandatory"
                  >
                    Campo obrigatório*
                  </div>
                </div>

                <div fxLayout="row" class="mb-2 mt-2">
                  <div class="form-group" fxLayout="column" fxFlex="48.8" style="margin-right: 23px;">
                    <label class="label-input form-label" for="certifies" id="label-certifies">
                      Certificações
                    </label>
                    <ng-select
                      id="select-certifies"
                      class="custom w-100"
                      name="certifies"
                      formControlName="certifies"
                      [items]="certifies"
                      [multiple]="true"
                      bindLabel="label"
                      bindValue="value"
                      [ngClass]="{
                        'certifications-none':
                          companyForm.get('certifies')?.value?.length == 0,
                        'certifications':
                          companyForm.get('certifies')?.value?.length > 0
                      }"
                    ></ng-select>
                  </div>

                  <div class="form-group" fxLayout="column" fxFlex>
                    <label
                      class="label-input form-label required"
                      for="hasTracker"
                      id="label-autonomous"
                    >
                      Autônomo?
                    </label>
                    <div fxLayout="row">
                      <div fxLayout="column">
                        <div class="form-check form-check-inline">
                          <input
                            #autonomousYesRef
                            id="autonomousYes"
                            class="form-check-input"
                            name="autonomous"
                            formControlName="autonomous"
                            type="radio"
                            value="true"
                          />
                          <label class="form-check-label" for="autonomousYes">
                            Sim
                          </label>
                        </div>
                      </div>
                      <div fxLayout="column">
                        <div class="form-check form-check-inline">
                          <input
                            #autonomousNoRef
                            id="autonomousNo"
                            class="form-check-input"
                            name="autonomous"
                            formControlName="autonomous"
                            type="radio"
                            value="false"
                          />
                          <label class="form-check-label" for="autonomousNo">
                            Não
                          </label>
                        </div>
                      </div>
                    </div>
                    <div
                    *ngIf="
                      companyForm.get('autonomous')?.invalid &&
                      companyForm.get('autonomous')?.touched
                    "
                    class="label-warning label-mandatory"
                  >
                    Campo obrigatório*
                    </div>
                  </div>
                </div>
              </div>
              <div class="row mb-2">
                <div class="col col-md-6 form-group">
                  <label
                    class="label-input form-label required"
                    for="hasTracker"
                    id="label-hasTracker"
                  >
                    Possui veículos com rastreador?
                  </label>
                  <div class="form-check form-check-inline">
                    <input
                      #hasTrackerYesRef
                      id="hasTrackerYes"
                      class="form-check-input"
                      name="hasTracker"
                      formControlName="hasTracker"
                      type="radio"
                      value="true"
                    />
                    <label class="form-check-label" for="hasTrackerYes">
                      Sim
                    </label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input
                      #hasTrackerNoRef
                      id="hasTrackerNo"
                      class="form-check-input"
                      name="hasTracker"
                      formControlName="hasTracker"
                      type="radio"
                      value="false"
                    />
                    <label class="form-check-label" for="hasTrackerNo">
                      Não
                    </label>
                  </div>
                  <div
                  *ngIf="
                    companyForm.get('hasTracker')?.invalid &&
                    companyForm.get('hasTracker')?.touched
                  "
                  class="label-warning label-mandatory"
                >
                  Campo obrigatório*
                  </div>
                </div>
              </div>

              <div fxLayout="row" fxLayoutAlign="end">
                <div fxLayout="column">
                  <button
                    id="btn-goback-stepper-2"
                    mat-flat-button
                    matStepperPrevious
                    class="btn-grey"
                    style="margin-right: 20px;"
                  >
                    Voltar
                  </button>
                </div>
                <div fxLayout="column">
                  <button
                    id="btn-prox-stepper-2"
                    mat-flat-button
                    matStepperNext
                    class="btn-green"
                    (click)="submitFormData()"
                  >
                    Próximo
                  </button>
                </div>
              </div>
            </div>
          </form>
        </mat-step>
        <mat-step>
          <ng-template matStepLabel>Conclusão</ng-template>
          <div class="card-body card-container"  style="margin-top: 1rem;">
            <div class="row mb-2 text-center">
              <h5 class="complete-title" id="title-conclusion">{{ titleConclusion }}</h5>
            </div>
            <div class="row mb-2 justify-content-center text-center">
              <p class="complete-text px-4" id="message-conclusion">
                {{ bodyConclusion }}
              </p>
            </div>
            <div class="row mb-2 justify-content-center">
              <div class="col-md-4">
                <img src="/assets/img/svg/New-message-pana.svg" alt="" id="img-finish" />
              </div>
            </div>
          </div>
        </mat-step>
      </mat-stepper>
    </div>
  </div>
</div>
