import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { first } from 'rxjs';
import { SnackbarDefaultService } from 'src/app/shared/services/snackbar-default.service';
import { MyCompanyFormService } from './my-company-form.service';
import { UFs, setor_atuacao_options } from 'src/app/shared/const/consts';
import { MatDialog } from '@angular/material/dialog';
import { DialogAddDefaultProductComponent } from '../dialog-add-default-product/dialog-add-default-product.component';
import { NgSelectComponent } from '@ng-select/ng-select';


interface ViaCepResponse {
  bairro: string;
  cep: string;
  complemento: string;
  ddd: string;
  gia: string;
  ibge: string;
  localidade: string;
  logradouro: string;
  siafi: string;
  uf: string;
}

@Component({
  selector: 'app-my-company-form',
  templateUrl: './my-company-form.component.html',
  styleUrls: ['./my-company-form.component.scss'],
})
export class MyCompanyFormComponent implements OnInit {
  companyForm: FormGroup;
  editCompany: boolean = false;
  id: any;
  activitySectorOptions = setor_atuacao_options;
  productType: any
  productName: string = ''
  UFs = UFs
  addNewProduct: boolean = false
  company: any
  isViaCepUpdating: boolean = true;

  @ViewChild('documentRef') documentRef: ElementRef;
  @ViewChild('legalNameRef') legalNameRef: ElementRef;
  @ViewChild('zipocodeRef') zipocodeRef: ElementRef;
  @ViewChild('provinceRef') provinceRef: NgSelectComponent;
  @ViewChild('cityRef') cityRef: ElementRef;
  @ViewChild('streetRef') streetRef: ElementRef;
  @ViewChild('numberRef') numberRef: ElementRef;
  @ViewChild('neighborhoodRef') neighborhoodRef: ElementRef;
  @ViewChild('activitySectorRef') activitySectorRef: NgSelectComponent;

  constructor(
    private fb: FormBuilder,
    private http: HttpClient,
    private myCompanyFormService: MyCompanyFormService,
    private _snackBar: SnackbarDefaultService,
    private route: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog,
  ) {
    this.companyForm = this.fb.group({
      document: ['', [Validators.required]],
      legalName: ['', [Validators.required]],
      // companyName: ['', [Validators.required]],
      zipcode: ['', [Validators.required]],
      province: ['', [Validators.required]],
      city: ['', [Validators.required]],
      street: ['', [Validators.required]],
      number: ['', [Validators.required]],
      neighborhood: ['', [Validators.required]],
      complement: ['',],
      activitySector: ['', [Validators.required]],
      transportationZone: ['']
    });

    this.companyForm.controls['zipcode'].valueChanges.subscribe(
      (value: string) => this.onZipcodeChange(value)
    );
  }

  ngOnInit(): void {
    this.id = this.route.snapshot.params['id'];

    if (this.id != undefined && this.id != null) {
      this.getShipper();
      this.isViaCepUpdating = false;
    }
  }

  getIdFromUrl() {
    return this.route.snapshot.paramMap.get('id');
  }

  onZipcodeChange(value: string) {
    if (this.isViaCepUpdating) {
      if (value.length < 8) return;
      const protocol = location.protocol;
      let zipsub = this.http
        .get<ViaCepResponse>(`${protocol}//viacep.com.br/ws/${value}/json/`)
        .subscribe((zipData) => {
          const addressObj = {
            province: zipData.uf,
            city: zipData.localidade,
            street: zipData.logradouro,
            complement: zipData.complemento != "" ? zipData.complemento : this.companyForm.get('complement').value,
            neighborhood: zipData.bairro,
          };
          this.companyForm.patchValue(addressObj);
          zipsub.unsubscribe();
        });
    }
  }

  private buildShipper() {
    let listaVazia: any[] = [];
    return {
      contatoReferencia: listaVazia,
      matriz: false,
      cnpj: this.companyForm.value.document,
      razaoSocial: this.companyForm.value.legalName,
      // nomeFantasia: this.companyForm.value.companyName,
      endereco: {
        bairro: this.companyForm.value.neighborhood,
        uf: this.companyForm.value.province,
        cidade: this.companyForm.value.city,
        cep: this.companyForm.value.zipcode,
        logradouro: this.companyForm.value.street,
        numero: this.companyForm.value.number,
        complemento: this.companyForm.value.complement,
      },
      zonaTransporte: this.companyForm.value.transportationZone,
      setorAtuacao: this.companyForm.value.activitySector
    };
  }

  private fillinFields(data: any) {
    this.companyForm.get('document').setValue(data.cnpj);
    this.companyForm.get('legalName').setValue(data.razaoSocial);
    this.companyForm.get('zipcode').setValue(data.endereco.cep);
    this.companyForm.get('province').setValue(data.endereco.uf);
    this.companyForm.get('city').setValue(data.endereco.cidade);
    this.companyForm.get('street').setValue(data.endereco.logradouro);
    this.companyForm.get('number').setValue(data.endereco.numero);
    this.companyForm.get('neighborhood').setValue(data.endereco.bairro);
    this.companyForm.get('complement')?.setValue(data.endereco.complemento);
    this.companyForm.get('activitySector')?.setValue(data.setorAtuacao);
    this.companyForm.get('transportationZone')?.setValue(data.zonaTransporte);

    let setor = this.activitySectorOptions.find(option => option.value === data.setorAtuacao);
    if (!setor) {
      setor = this.activitySectorOptions.find(option => option.value === 'naoInformado');
    }
    this.companyForm.get('activitySector')?.setValue(setor.value);

    this.productType = data.produto?.length > 0 ? data.produto : null
    this.productName = data.produto?.length > 0 ? this.formatProductType(data.produto[0]) : ''
    this.isViaCepUpdating = true;

  }

  getShipper() {
    this.myCompanyFormService
      .getShipper(this.getIdFromUrl())
      .pipe()
      .subscribe((data) => {
        if (data) {
          this.company = data
          console.log(this.company)
          this.fillinFields(data);
        }
      });
  }

  editShipper(shipper: object) {
    this.companyForm.markAllAsTouched();
    if (this.companyForm.invalid) {
      this._snackBar.openSnackBarWarning('Há campos que necessitam de atenção.');
      if(this.companyForm.controls['document'].invalid) {
        this.documentRef.nativeElement.focus();
      } else if(this.companyForm.controls['legalName'].invalid) {
        this.legalNameRef.nativeElement.focus();
      } else if(this.companyForm.controls['zipcode'].invalid) {
        this.zipocodeRef.nativeElement.focus();
      } else if(this.companyForm.controls['province'].invalid) {
        this.provinceRef.focus();
      } else if(this.companyForm.controls['city'].invalid) {
        this.cityRef.nativeElement.focus();
      } else if(this.companyForm.controls['street'].invalid) {
        this.streetRef.nativeElement.focus();
      } else if(this.companyForm.controls['number'].invalid) {
        this.numberRef.nativeElement.focus();
      } else if(this.companyForm.controls['neighborhood'].invalid) {
        this.neighborhoodRef.nativeElement.focus();
      } else if(this.companyForm.controls['activitySector'].invalid) {
        this.activitySectorRef.focus();
      }
      return;
    }

    this.myCompanyFormService
      .createShipper(Object.assign(shipper, { id: this.id }))
      .pipe(first())
      .subscribe({
        next: (result) => {
          console.log(result)
          if (this.addNewProduct) {
            this.addProduct(result)
          }
          this._snackBar.openSnackBarSuccess('Empresa atualizada com sucesso.');
          setTimeout(() => {
            this.redirect();
          }, 1000);
        },
        error: (e) => {
          this._snackBar.openSnackBarError('Erro ao atualizar empresa - ' + e.error);
        },
      });
  }

  submitUserData() {
    this.companyForm.markAllAsTouched();
    if (this.companyForm.invalid) {
      this._snackBar.openSnackBarWarning('Há campos que necessitam de atenção.');
      if(this.companyForm.controls['document'].invalid) {
        this.documentRef.nativeElement.focus();
      } else if(this.companyForm.controls['legalName'].invalid) {
        this.legalNameRef.nativeElement.focus();
      } else if(this.companyForm.controls['zipcode'].invalid) {
        this.zipocodeRef.nativeElement.focus();
      } else if(this.companyForm.controls['province'].invalid) {
        this.provinceRef.focus();
      } else if(this.companyForm.controls['city'].invalid) {
        this.cityRef.nativeElement.focus();
      } else if(this.companyForm.controls['street'].invalid) {
        this.streetRef.nativeElement.focus();
      } else if(this.companyForm.controls['number'].invalid) {
        this.numberRef.nativeElement.focus();
      } else if(this.companyForm.controls['neighborhood'].invalid) {
        this.neighborhoodRef.nativeElement.focus();
      } else if(this.companyForm.controls['activitySector'].invalid) {
        this.activitySectorRef.focus();
      }
      return;
    }

    let shipper = this.buildShipper();

    if (this.id) {
      this.editShipper(shipper);
    } else {
      this.myCompanyFormService
        .createShipper(shipper)
        .pipe(first())
        .subscribe({
          next: (result) => {
            console.log(result)
            this._snackBar.openSnackBarSuccess(
              'Empresa cadastrada com sucesso.'
            );
            if (this.addNewProduct) {
              this.addProduct(result)
            }
            setTimeout(() => {
              this.redirect();
            }, 1000);
          },
          error: () => {
            this._snackBar.openSnackBarError('Erro ao cadastrar empresa.');
          },
        });
    }
  }

  public redirect() {
    this.router
      .navigate([`configurations-shipper/companies`])
      .catch((err) => err);
  }

  OpenDialogProductType() {
    let dialogRef = this.dialog.open(DialogAddDefaultProductComponent)

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.productType = result.value
        this.productName = result.label
        this.addNewProduct = true
      }
    })
  }

  addProduct(data: any) {
    let obj: any
    if (this.id) {
      obj = {
        empresa: { id: this.id },
        produtoList: [this.productType],
      }
    } else {
      obj = {
        empresa: { id: data.id },
        produtoList: [this.productType],
      }
    }

    this.myCompanyFormService.saveProduct(obj).pipe(first())
      .subscribe({
        next: (data) => {
          console.log(data)
          this.addNewProduct = false
        },
        error: (error) => {
          console.log(error)
        }
      })

  }

  removeProduct() {
    if (this.id && !this.addNewProduct) {
      let obj = {
        idEmpresa: this.id,
        product: this.productType
      }
      this.myCompanyFormService.deleteProduct(obj).pipe(first())
        .subscribe({
          next: (data) => {
            this.productType = null
            this.addNewProduct = false
          },
          error: (error) => {
            console.log(error)
          }
        })
    } else {
      this.productType = null
      this.addNewProduct = false
    }
  }

  formatProductType(product: string) {
    switch (product) {
      case 'CARGAS_FRIGORIFICAS_PERECIVEIS':
        return 'Cargas Frigoríficas - Perecíveis';
      case 'CARGAS_FRIGORIFICAS_CONGELADAS':
        return 'Cargas Frigoríficas - Congeladas';
      case 'CARGAS_A_GRANEL_LIQUIDO':
        return 'Cargas a Granel - Líquido';
      case 'CARGAS_A_GRANEL_SOLIDO':
        return 'Cargas a Granel - Sólido';
      case 'CARGAS_VIVAS':
        return 'Cargas Vivas';
      case 'CARGAS_INDIVISIVEIS_E_EXCEPCIONAIS_DE_GRANDE_PORTE':
        return 'Cargas Indivisíveis e excepcionais de grande porte';
      case 'CARGAS_SECAS':
        return 'Cargas Secas';
      case 'CARGAS_PERIGOSAS':
        return 'Cargas Perigosas';
        case 'QUIMICO':
          return 'Químico';
        case 'OUTROS':
          return 'Outros';
      default:
        return '';
    }
  }

}
