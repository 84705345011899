<mat-card class="container-users card-container" *ngIf="loaded; else isLoading">
  <mat-card-content>
    <h6>Meus usuários</h6>
    <div class="d-flex mt-4">
      <div *ngIf="isAdmin" class="flex-grow-1">
        <a [routerLink]="[newUser]" mat-flat-button class="btn-grey">
          <i class="ri-add-line"></i> Novo
        </a>
      </div>
    </div>
    <div class="mat-table-container">
      <table mat-table [dataSource]="dataSource" class="mat-table mt-4">
        <caption hidden>
          Tabela convites
        </caption>
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef class="table-head-color">
            {{ headerTable.lbName }}
          </th>
          <td mat-cell *matCellDef="let element" class="table-row">
            {{ element.name }}
          </td>
        </ng-container>

        <ng-container matColumnDef="email">
          <th mat-header-cell *matHeaderCellDef class="table-head-color">
            {{ headerTable.lbEmail }}
          </th>
          <td mat-cell *matCellDef="let element" class="table-row">
            {{ element.email }}
          </td>
        </ng-container>

        <ng-container matColumnDef="status">
          <th mat-header-cell *matHeaderCellDef class="table-head-color">
            {{ headerTable.lbStatus }}
          </th>
          <td mat-cell *matCellDef="let element" class="table-row">
            <label
              for=""
              [ngClass]="{
                'label-active': element.status === 'Ativo',
                'label-inactive': element.status === 'Inativo'
              }"
            >
              {{ element.status }}
            </label>
          </td>
        </ng-container>

        <ng-container matColumnDef="actions">
          <th
            mat-header-cell
            *matHeaderCellDef
            class="table-head-color column-actions"
          >
            {{ headerTable.lbActions }}
          </th>
          <td mat-cell *matCellDef="let element" class="table-row">
            <button mat-button [matMenuTriggerFor]="menu" class="dropdown-button">
              {{ headerTable.lbActions }}
              <i class="ri-arrow-down-s-line"></i>
            </button>
            <mat-menu #menu="matMenu">
              <div *ngIf="isAdmin && element.id == userId">
                  <a
                    mat-menu-item
                    class="dropdown-button-item"
                    (click)="redirect('Editar', element)"
                  >
                    Editar
                  </a>
              </div>
              <div *ngIf="isAdmin && element.id != userId">
                  <a
                    mat-menu-item
                    class="dropdown-button-item"
                    *ngFor="let action of options"
                    (click)="redirect(action, element)"
                  >
                    {{ action }}
                  </a>
              </div>
              <div *ngIf="!isAdmin">
                <a
                  mat-menu-item
                  class="dropdown-button-item"

                >
                  -
                </a>
              </div>
            </mat-menu>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
    </div>
    <mat-paginator
      [pageSizeOptions]="[5, 10, 20, 50, 100]"
      showFirstLastButtons
      aria-label="Select page of periodic elements"
      (page)="handlePageEvent($event)"
      [length]="totalElements"
      [pageIndex]="page"
      [pageSize]="size"
    >
    </mat-paginator>
  </mat-card-content>
</mat-card>

<ng-template #isLoading>
  <mat-progress-bar mode="indeterminate" color="primary"></mat-progress-bar>
</ng-template>
