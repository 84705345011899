import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { LoginComponent } from './login/login.component';
import { CarrierRegistrationComponent } from './carrier-registration/carrier-registration.component';
import { RedefinePasswordComponent } from './redefine-password/redefine-password.component';
import { UserActivationComponent } from './user-activation/user-activation.component';
import { PartnerInvitationComponent } from './partner-invitation/partner-invitation.component';
import { LandingHomeComponent } from './landing-home/landing-home.component';
import { LandingEmbarcadorComponent } from './landing-embarcador/landing-embarcador.component';
import { LandingTransportadorComponent } from './landing-transportador/landing-transportador.component';
import { ErrorAcceptingPartnerShipComponent } from '../carrier/error-accepting-partner-ship/error-accepting-partner-ship.component';

const routes: Routes = [
  {
    path: '',
    component: LandingHomeComponent,
  },
  {
    path: 'im-a-shipper',
    component: LandingEmbarcadorComponent,
  },
  {
    path: 'im-a-carrier',
    component: LandingTransportadorComponent,
  },
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: 'no-auth-carrier',
    component: CarrierRegistrationComponent,
  },
  {
    path: 'redefine-password/:id/:hash',
    component: RedefinePasswordComponent,
  },
  {
    path: 'activated-user',
    component: UserActivationComponent,
  },
  {
    path: 'convite-parceiro',
    component: PartnerInvitationComponent,
  },
  {
    path: 'error-accepting-partnership',
    component: ErrorAcceptingPartnerShipComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AuthenticationRoutingModule {}
