import { Component, OnChanges, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { SnackbarDefaultService } from 'src/app/shared/services/snackbar-default.service';
import { CarrierPortalService } from '../carrier-portal.service';
import { first, map } from 'rxjs';
import * as xmljs from 'xml-js';

@Component({
  selector: 'app-conveyor-login',
  templateUrl: './portal-login.component.html',
  styleUrls: ['./portal-login.component.scss'],
})
export class PortalLoginComponent implements OnInit, OnChanges {
  loginForm: FormGroup;
  visiblePasswordCheck: boolean = false;
  loaded = true;
  sending = false;

  page: number = 0;
  size: number = 10000;

  shippers: any[] = [];

  constructor(
    private fb: FormBuilder,
    private snackbar: SnackbarDefaultService,
    private dialogRef: MatDialogRef<PortalLoginComponent>,
    private portalService: CarrierPortalService
  ) {
    this.loginForm = this.fb.group({
      user: ['', Validators.required],
      password: ['', Validators.required],
      database: ['', Validators.required],
      shipper: ['', Validators.required],
    });
  }

  ngOnInit() {
    this.getShipper();
  }

  ngOnChanges() {
    this.getShipper();
  }

  close() {
    this.dialogRef.close(false);
  }

  visiblePassword(elementId: string) {
    const element = document.getElementById(elementId);

    this.visiblePasswordCheck = !this.visiblePasswordCheck;
    element?.setAttribute(
      'type',
      this.visiblePasswordCheck ? 'text' : 'password'
    );
  }

  login() {
    this.loginForm.disable();
    this.loaded = false;
    this.sending = true;
    let obj = {
      usuario: this.loginForm.get('user').value,
      senha: this.loginForm.get('password').value,
      banco: this.loginForm.get('database').value,
      idClienteEmbarcador: this.loginForm.get('shipper').value,
    };

    this.portalService
      .login(obj)
      .pipe(first())
      .subscribe({
        next: (dados) => {
          this.selectSession(dados);
          this.dialogRef.close(true);
          this.loaded = true;
          this.sending = false;
          this.loginForm.enable();
        },
        error: (err) => {
          console.log(err);
          this.snackbar.openSnackBarError(
            'Erro ao efetuar login no Portal: ' + err.error
          );
          this.loaded = true;
          this.sending = false;
          this.loginForm.enable();
        },
      })
  }

  getShipper() {
    this.loaded = false;
    this.loginForm.disable();
    let obj = {
      page: this.page,
      size: this.size,
    };

    this.portalService
      .getClients(obj)
      .pipe(first())
      .subscribe({
        next: (dados) => {
          let data = dados.pageList;

          for (let d of data) {
            if (this.shippers.length == 0) {
              this.shippers.push({label: d.razaoSocial, value: d.id});
            } else {
              if (!this.shippers.includes(d.id)) {
                this.shippers.push({label: d.razaoSocial, value: d.id});
              }
            }
          }
          this.loaded = true;
          this.loginForm.enable();
        },
        error: (err) => {
          console.log(err);
          this.loaded = true;
          this.loginForm.enable();
        },
      });
  }

  selectSession(data: any) {
    let sessao = data.servico
    let idClienteEmbarcador = this.loginForm.get('shipper').value;
    let session: { idsessao: string; token: string; shipper: string, idClienteEmbarcador: number } = {
      idsessao: sessao.idsessao,
      token: sessao.token,
      idClienteEmbarcador: idClienteEmbarcador,
      shipper: this.shippers.find((s) => s.value == idClienteEmbarcador)?.label,
    };

    sessionStorage.setItem('session', JSON.stringify(session));
  }
}
