<mat-card class="container-my-companies card-container">
  <mat-card-content>
    <h6 *ngIf="!editCompany" class="mt-2">Cadastro de empresa</h6>
    <h6 *ngIf="editCompany" class="mt-2">Edição de empresa</h6>

    <div class="d-flex flex-row">
      <div class="mt-4 w-100">
        <form [formGroup]="companyForm">
          <div class="row mb-2">
            <div class="col col-md-6 form-group position-relative">
              <label class="label-input form-label required" for="document">
                CNPJ
              </label>
              <input #documentRef matInput mask="00.000.000/0000-00" name="document" class="form-control input-data w-100"
                formControlName="document" placeholder="Digite o CNPJ" required [ngClass]="{
                    'is-invalid':
                    companyForm.get('document')?.invalid &&
                    companyForm.get('document')?.touched,
                    'is-valid':
                    companyForm.get('document')?.valid &&
                    companyForm.get('document')?.touched || companyForm.get('document')?.valid,
                  }" />
              <div *ngIf="
                  companyForm.get('document')?.invalid &&
                  companyForm.get('document')?.touched &&
                  companyForm.get('document')?.value.length == 0
                " class="invalid-feedback label-mandatory">
                Campo obrigatório*
              </div>
              <div *ngIf="
                  (companyForm.get('document').value.length > 0 &&
                    companyForm.get('document').value.length < 18 &&
                    companyForm.get('document')?.touched &&
                    companyForm.get('document')?.errors) ||
                  (companyForm.get('document')?.errors &&
                    companyForm.get('document')?.value.length > 0)
                " class="invalid-feedback label-mandatory">
                CNPJ inválido!
              </div>
            </div>
            <div class="col col-md-6 form-group">
              <label class="label-input form-label required" for="legalName">
                Razão social
              </label>
              <input #legalNameRef matInput name="legalName" class="form-control input-data w-100" formControlName="legalName"
                placeholder="Digite a razão social" required [ngClass]="{
                  'is-invalid':
                  companyForm.get('legalName')?.invalid &&
                  companyForm.get('legalName')?.touched,
                  'is-valid':
                  companyForm.get('legalName')?.valid &&
                  companyForm.get('legalName')?.touched || companyForm.get('legalName')?.valid,
                }" />
              <div *ngIf="
                  companyForm.get('legalName')?.invalid &&
                  companyForm.get('legalName')?.touched
                " class="invalid-feedback label-mandatory">
                Campo obrigatório*
              </div>
            </div>
          </div>
          <div class="row mb-2">
            <div class="col col-md-6 form-group">
              <label class="label-input form-label required" for="activitySector">
                Setor de Atuação
              </label>
              <ng-select #activitySectorRef name="activitySector" formControlName="activitySector" [items]="activitySectorOptions"
                [closeOnSelect]="true" [searchable]="true" [clearable]="false" bindLabel="label" bindValue="value"
                required [ngClass]="{
                  'invalid is-invalid':
                    companyForm.get('activitySector')?.invalid &&
                    companyForm.get('activitySector')?.touched &&
                    companyForm.get('activitySector').value === '',
                  'valid is-valid':
                    companyForm.get('activitySector')?.valid &&
                    companyForm.get('activitySector')?.touched &&
                    companyForm.get('activitySector').value !== '' || companyForm.get('activitySector')?.valid ,
                }">
                <ng-template ng-label-tmp let-item="item" let-index="index">
                  {{ item.label }}
                </ng-template>
              </ng-select>
              <div *ngIf="
                  companyForm.get('activitySector')?.invalid &&
                  companyForm.get('activitySector').value === ''
                " class="invalid-feedback label-mandatory">
                Campo obrigatório*
              </div>
            </div>
            <div class="col col-md-6 form-group">
              <label class="label-input form-label required" for="zipcode">
                CEP
              </label>
              <input #zipocodeRef matInput mask="00000-000" name="zipcode" class="form-control input-data w-100"
                formControlName="zipcode" placeholder="Digite o CEP" required [ngClass]="{
                  'is-invalid':
                  companyForm.get('zipcode')?.invalid &&
                  companyForm.get('zipcode')?.touched,
                  'is-valid':
                  companyForm.get('zipcode')?.valid &&
                  companyForm.get('zipcode')?.touched || companyForm.get('zipcode')?.valid,
                }" />
              <div *ngIf="
                  companyForm.get('zipcode')?.invalid &&
                  companyForm.get('zipcode')?.touched &&
                  companyForm.get('zipcode').value.length == 0
                " class="invalid-feedback label-mandatory">
                Campo obrigatório*
              </div>
              <div *ngIf="
                  companyForm.get('zipcode').value.length > 0 &&
                  companyForm.get('zipcode').value.length < 10 &&
                  companyForm.get('zipcode')?.touched &&
                  companyForm.get('zipcode')?.errors
                " class="invalid-feedback label-mandatory">
                CEP inválido!
              </div>
            </div>
          </div>
          <div class="row mb-2">
            <div class="col col-md-6 form-group">
              <label class="label-input form-label required" for="province">
                UF
              </label>
              <ng-select #provinceRef name="province" formControlName="province" [items]="UFs" [closeOnSelect]="true"
                [searchable]="true" [clearable]="false" bindLabel="value" bindValue="value" required [ngClass]="{
                  'invalid is-invalid':
                    companyForm.get('province')?.invalid &&
                    companyForm.get('province')?.touched &&
                    companyForm.get('province').value === '',
                  'valid is-valid':
                    companyForm.get('province')?.valid &&
                    companyForm.get('province')?.touched &&
                    companyForm.get('province').value !== '' || companyForm.get('province')?.valid ,

                }">
                <ng-template ng-label-tmp let-item="item" let-index="index">
                  {{ item.value }}
                </ng-template>
              </ng-select>
              <div *ngIf="
                  companyForm.get('province')?.invalid &&
                  companyForm.get('province').value === ''
                " class="invalid-feedback label-mandatory">
                Campo obrigatório*
              </div>
            </div>
            <div class="col col-md-6 form-group">
              <label class="label-input form-label required" for="city">
                Cidade
              </label>
              <input #cityRef matInput name="city" class="form-control input-data w-100" formControlName="city"
                placeholder="Digite o nome da cidade" required [ngClass]="{
                  'is-invalid':
                  companyForm.get('city')?.invalid &&
                  companyForm.get('city')?.touched,
                  'is-valid':
                  companyForm.get('city')?.valid &&
                  companyForm.get('city').value !== '',
                }" />
              <div *ngIf="
                  companyForm.get('city')?.invalid &&
                  companyForm.get('city')?.touched
                " class="invalid-feedback label-mandatory">
                Campo obrigatório*
              </div>
            </div>
          </div>
          <div class="row mb-2">
            <div class="col col-md-6 form-group">
              <label class="label-input form-label required" for="street">
                Logradouro
              </label>
              <input #streetRef matInput name="street" class="form-control input-data w-100" formControlName="street"
                placeholder="Digite o a logradouro" required [ngClass]="{
                  'is-invalid':
                    companyForm.get('street')?.invalid &&
                    companyForm.get('street')?.touched,
                  'is-valid':
                    companyForm.get('street')?.valid &&
                    companyForm.get('street').value !== ''
                }" />
              <div *ngIf="
                  companyForm.get('street')?.invalid &&
                  companyForm.get('street')?.touched
                " class="invalid-feedback label-mandatory">
                Campo obrigatório*
              </div>
            </div>
            <div class="col col-md-6 form-group">
              <label class="label-input form-label required" for="number">
                Número
              </label>
              <input #numberRef matInput name="number" class="form-control input-data w-100" formControlName="number"
                placeholder="Digite o número" required [ngClass]="{
                  'is-invalid':
                  companyForm.get('number')?.invalid &&
                  companyForm.get('number')?.touched,
                  'is-valid':
                  companyForm.get('number')?.valid &&
                  companyForm.get('number')?.touched || companyForm.get('number')?.valid,
                }" />
              <div *ngIf="
                  companyForm.get('number')?.invalid &&
                  companyForm.get('number')?.touched
                " class="invalid-feedback label-mandatory">
                Campo obrigatório*
              </div>
            </div>
          </div>
          <div class="row mb-2">
            <div class="col col-md-6 form-group">
              <label class="label-input form-label required" for="neighborhood">
                Bairro
              </label>
              <input #neighborhoodRef matInput name="neighborhood" class="form-control input-data w-100" formControlName="neighborhood"
                placeholder="Digite um bairro" required [ngClass]="{
                  'is-invalid':
                    companyForm.get('neighborhood')?.invalid &&
                    companyForm.get('neighborhood')?.touched,
                  'is-valid':
                    companyForm.get('neighborhood')?.valid &&
                    companyForm.get('neighborhood').value !== ''
                }" />
              <div *ngIf="
                  companyForm.get('neighborhood')?.invalid &&
                  companyForm.get('neighborhood')?.touched
                " class="invalid-feedback label-mandatory">
                Campo obrigatório*
              </div>
            </div>
            <div class="col col-md-6 form-group">
              <label class="label-input form-label" for="complement">
                Complemento
              </label>
              <input matInput name="complement" class="form-control input-data w-100" formControlName="complement"
                placeholder="Digite um complemento" [ngClass]="{
                  'is-valid':
                    (companyForm.get('complement')?.valid &&
                      companyForm.get('complement').value !== '') ||
                    (companyForm.get('complement')?.valid &&
                      companyForm.get('complement')?.value.length > 0)
                }" />
            </div>
          </div>

          <div class="row mb-2 mt-3">
            <div class="col col-md-6 form-group">
            <div class="transportation-zone">
              <label class="label-input form-label" for="complement">
                Zona de transporte
              </label>
              <div fxLayout="row">
                <i class="ri-information-fill info-green hover-text">
                  <div class="tooltip-text right-tooltip">
                    <p class="p-tooltip">
                      Este campo apresenta a zona de transporte cadastrada no GKOFrete.
                    </p>
                  </div>
                </i>
              </div>
            </div>
             
              <input matInput name="transportationZone" class="form-control input-data w-100" formControlName="transportationZone"
                placeholder="Digite a zona de transporte" [ngClass]="{
                  'is-valid':
                    (companyForm.get('transportationZone')?.valid &&
                      companyForm.get('transportationZone').value !== '' && 
                      companyForm.get('transportationZone').value != null
                      ) 
                }" />
            </div>
          </div>

          <div class="mt-4">
            <h6>Tipo de produto padrão</h6>
          </div>

          <div class="mt-4">
            <button *ngIf="!productType" mat-flat-button (click)="OpenDialogProductType()"
              class="btn-green">
              Adicionar produto
            </button>
          </div>


          <div fxLayout="row" class="mt-4" *ngIf="productType != null">
            <div fxLayout="column" fxFlex="49" style="margin-right: 10px;">
              <span class="product-container">{{ productName }}</span>
            </div>
            <div fxLayout="column">
              <button *ngIf="productType != null" mat-flat-button (click)="removeProduct()" class="btn-grey">
                Remover produto
              </button>
            </div>
          </div>

          <div class="row justify-content-end">
            <div class="col col-md-2 text-end mt-3">
              <button mat-flat-button class="btn-green" (click)="submitUserData()">
                Salvar
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </mat-card-content>
</mat-card>
