import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { MatButtonModule } from '@angular/material/button';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';

import { NgSelectModule } from '@ng-select/ng-select';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';

import { FooterComponent } from './footer/footer.component';
import { NavbarComponent } from './navbar/navbar.component';
import { ToolbarComponent } from './toolbar/toolbar.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { PageTitleComponent } from './page-title/page-title.component';
import { AccordionCheckComponent } from './accordion-check/accordion-check.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatTableModule } from '@angular/material/table';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatCardModule } from '@angular/material/card';
import { FlexLayoutModule } from '@angular/flex-layout';
import { SnackbarComponent } from './snackbar/snackbar.component';
import { DialogAttachmentComponent } from './dialog-attachment/dialog-attachment.component';
import { DialogConfirmComponent } from './dialog-confirm/dialog-confirm.component';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSliderModule } from '@angular/material/slider';
import { DetailsComponent } from './details/details.component';
import { RedefinePasswordDialogComponent } from './redefine-password-dialog/redefine-password-dialog.component';
import { TermosPrivacidadeComponent } from './termos-privacidade/termos-privacidade.component';
import { RequirementsComponent } from './requirements/requirements.component';
import { DialogRequirementsRegisterComponent } from './dialog-requirements-register/dialog-requirements-register.component';
import { InviteLinkComponent } from './invite-link/invite-link.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CepPipe } from '../utils/cep.pipe';
import { CpfCnpjPipe } from '../utils/cpfCnpj.pipe';
import { AdvancedFilterComponent } from './advanced-filter/advanced-filter.component';
import { AdvancedFilterModalComponent } from './advanced-filter-modal/advanced-filter-modal.component';
import { MatStepperModule } from '@angular/material/stepper';
import { MatPaginatorModule } from '@angular/material/paginator';
import { TutorialsComponent } from './tutorials/tutorials.component';
import { MatSortModule } from '@angular/material/sort';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { FormatStringPipe } from './formatString.pipe';
import { ChatComponent } from './chat/chat.component';
import { ChatFormmatDatePipe } from './chat/chat.formmatDate.pipe';
import { MapChartComponent } from './map-chart/map-chart.component';
import { HighchartsChartModule } from 'highcharts-angular';
import { DialogBlockOffersTrialComponent } from './dialog-block-offers-trial/dialog-block-offers-trial.component';
import { ExportCSVComponent } from './export-csv/export-csv.component';
import { SatPopoverModule } from '@ncstate/sat-popover';
import { FilterFreightOfferComponent } from './filter-freight-offer/filter-freight-offer.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MaskDirectiveModule } from '../directives/mask-directive/mask-directive.module';
import {MatRadioModule} from '@angular/material/radio';

@NgModule({
  declarations: [
    FooterComponent,
    NavbarComponent,
    ToolbarComponent,
    SidebarComponent,
    PageTitleComponent,
    AccordionCheckComponent,
    SnackbarComponent,
    DialogAttachmentComponent,
    DialogConfirmComponent,
    DetailsComponent,
    RedefinePasswordDialogComponent,
    TermosPrivacidadeComponent,
    InviteLinkComponent,
    AdvancedFilterComponent,
    AdvancedFilterModalComponent,
    RequirementsComponent,
    DialogRequirementsRegisterComponent,
    CepPipe,
    CpfCnpjPipe,
    FormatStringPipe,
    TutorialsComponent,
    ChatComponent,
    ChatFormmatDatePipe,
    MapChartComponent,
    DialogBlockOffersTrialComponent,
    ExportCSVComponent,
    FilterFreightOfferComponent
  ],
  exports: [
    FooterComponent,
    NavbarComponent,
    ToolbarComponent,
    SidebarComponent,
    PageTitleComponent,
    AccordionCheckComponent,
    SnackbarComponent,
    DialogAttachmentComponent,
    DialogConfirmComponent,
    DetailsComponent,
    RedefinePasswordDialogComponent,
    TermosPrivacidadeComponent,
    RequirementsComponent,
    DialogRequirementsRegisterComponent,
    InviteLinkComponent,
    AdvancedFilterComponent,
    AdvancedFilterModalComponent,
    ChatComponent,
    FormatStringPipe,
    ChatFormmatDatePipe,
    MapChartComponent,
    CepPipe,
    ExportCSVComponent,
    FilterFreightOfferComponent],
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    MatButtonModule,
    MatAutocompleteModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    MatSidenavModule,
    MatToolbarModule,
    MatCheckboxModule,
    MatCardModule,
    MatListModule,
    MatMenuModule,
    MatExpansionModule,
    MatTooltipModule,
    FlexLayoutModule,
    MatTableModule,
    MatDialogModule,
    MatSliderModule,
    NgSelectModule,
    NgxMaskDirective,
    NgxMaskPipe,
    NgxDropzoneModule,
    ReactiveFormsModule,
    NgbModule,
    MatStepperModule,
    MatPaginatorModule,
    MatSortModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    HighchartsChartModule,
    SatPopoverModule,
    MatDatepickerModule,
    AutocompleteLibModule,
    MaskDirectiveModule,
    MatRadioModule
  ],
  schemas: [NO_ERRORS_SCHEMA],
  providers: [provideNgxMask()],
})
export class SharedModule { }
