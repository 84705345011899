import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { first } from 'rxjs';
import { MyProfileService } from 'src/app/configurations-carrier/my-profile/my-profile.service';
import { MaskService } from 'src/app/shared/services/mask.service';
import { SnackbarDefaultService } from 'src/app/shared/services/snackbar-default.service';
import { ConfigurationsShipperService } from './../configurations-shipper.service';
import { RedefinePasswordDialogComponent } from 'src/app/shared/redefine-password-dialog/redefine-password-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { RedefinePasswordDialogService } from 'src/app/shared/services/redefine-password-dialog.service';
import { environment } from 'src/environments/environment';
import {ImageService} from 'src/app/shared/services/image.service'

export interface HeaderTable {
  lbCorporateName: string;
  lbCnpj: string;
  lbAddress: string;
}


@Component({
  selector: 'app-my-profile',
  templateUrl: './my-profile.component.html',
  styleUrls: ['./my-profile.component.scss'],
})
export class MyProfileComponent implements OnInit {
  public profileForm: FormGroup = this.formBuilder.group({
    user: ['', Validators.required],
    email: ['', Validators.compose([Validators.required, Validators.email])],
    phone: ['', Validators.required],
  });

  displayedColumns: string[] = [
    'corporateName',
    'cnpj',
    'address',
  ];
  dataSource = new MatTableDataSource();

  options: string[] = ['Editar'];
  usuario: any;
  @ViewChild(MatPaginator)
  paginator!: MatPaginator;
  page: number = 0;
  size: number = 50;
  totalElements: number = 0;
  gridData: any = [];

  perfilImage: string

  @ViewChild('userRef') userRef: ElementRef;
  @ViewChild('phoneRef') phoneRef: ElementRef;


  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }

  headerTable: HeaderTable = {
    lbCorporateName: 'Razão Social',
    lbCnpj: 'CNPJ',
    lbAddress: '	Endereço',
  };

  constructor(
    private formBuilder: FormBuilder,
    private configShipperService: ConfigurationsShipperService,
    private myProfileService: MyProfileService,
    private snackBar: SnackbarDefaultService,
    private redefinePasswordDialogService: RedefinePasswordDialogService,
    private maskService: MaskService,
    private dialog: MatDialog,
    private imageService: ImageService
  ) {}

  ngOnInit(): void {
    this.usuario = JSON.parse(localStorage.getItem('usuario')!);
    this.profileForm.get('user')!.setValue(this.usuario.nome);
    this.profileForm.get('email')!.setValue(this.usuario.email);
    this.profileForm.get('phone')!.setValue(this.usuario.telefone);

    this.getEmbarcadoresAssociados();


  if(this.usuario.imagem === undefined || this.usuario.imagem === null){
    this.perfilImage = 'assets/img/svg/abstract-user-flat-grey.svg'
  }else{
    this.perfilImage = environment.AWS_S3_ASSETS+this.usuario.imagem
  }

  }

  public submitForm() {
    this.profileForm.markAllAsTouched();

    if (this.profileForm.invalid) {
      this.snackBar.openSnackBarWarning('Há campos que necessitam de atenção');
      if(this.profileForm.controls['user'].invalid){
        this.userRef.nativeElement.focus();
      }else if(this.profileForm.controls['phone'].invalid){
        this.phoneRef.nativeElement.focus();
      }
      return;
    }

    let perfil = {
      id: this.usuario.id,
      nome: this.profileForm.get('user')!.value,
      telefone: this.profileForm.get('phone')!.value,
    };
    this.configShipperService
      .atualizaPerfil(perfil)
      .pipe(first())
      .subscribe((dados: any) => {
        if (dados) {
          this.configShipperService
            .getUserById(this.usuario.id)
            .pipe(first())
            .subscribe((data: any) => {
              this.usuario.nome = data.nome;
              this.usuario.telefone = data.telefone;
              localStorage.setItem('usuario', JSON.stringify(this.usuario));
              this.snackBar.openSnackBarSuccess('Dados alterados com sucesso');
            });
        }
      });
  }

  changePassword() {
    this.dialog.open(RedefinePasswordDialogComponent, {disableClose:true});
  }

  public getEmbarcadoresAssociados() {
    const size = this.size;
    const page = this.page;

    const paramsObj = {
      page,
      size,
    };

    this.configShipperService
      .getShipperByUser(paramsObj, null)
      .pipe(first())
      .subscribe((dados) => {
        this.gridData = dados.content?.map((shipper: any) => ({
          corporateName: shipper.empresa.razaoSocial.toUpperCase(),
          cnpj: this.maskService.formatCNPJ(shipper.empresa.cnpj),
          address: this.getAddress(shipper.empresa),
        }));
        this.totalElements = dados.totalElements;
        this.dataSource = new MatTableDataSource(this.gridData);
      });
  }

  public getAddress(address: any) {
    if(address && address.cidade && address.uf){
    return `${address.cidade} - ${address.uf}`;
    }else{
      return " - "
    }
  }

  public handlePageEvent(e: PageEvent) {
    if (this.size != e.pageSize) {
      this.page = 0;
    } else {
      this.page = e.pageIndex;
    }
    this.size = e.pageSize;
    this.getEmbarcadoresAssociados();
  }

  public updateFile(event: any) {

    this.imageService.updateFile(event);

    setTimeout(() => {
      window.location.reload();
      this.snackBar.openSnackBarSuccess('Imagem alterada com sucesso');
    }, 6000);

}



}
