import { setor_atuacao_options } from 'src/app/shared/const/consts';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MySuppliersService } from '../my-suppliers/my-suppliers.service';

interface Supplier {
  name: string;
  id: number;
  document: string;
  address: Address;
  activitySector: string;
}

interface Address {
  zipcode: string;
  province: string;
  city: string;
  street: string;
  number: string;
  neighborhood: string;
  complement: string;
}

@Component({
  selector: 'app-supplier-details',
  templateUrl: './supplier-details.component.html',
  styleUrls: ['./supplier-details.component.scss'],
})
export class SupplierDetailsComponent implements OnInit {
  nameColumns: any = {
    lbName: 'Razão Social',
    lbDocument: 'CPNJ',
    lbactivitySector: 'Setor de Atuação',
    lbZipcode: 'CEP',
    lbProvince: 'Estado',
    lbCity: 'Cidade',
    lbStreet: 'Logradouro',
    lbnumber: 'Número',
    lbNeighborhood: 'Bairro',
    lbComplement: 'Complemento',
  };

  id: any;
  supplier: Supplier;
  activitySectorOptions = setor_atuacao_options;

  constructor(
    private route: ActivatedRoute,
    private service: MySuppliersService
  ) {}

  ngOnInit(): void {
    this.id = this.getIdFromUrl();
    this.getSuppliers();
  }

  getIdFromUrl() {
    return this.route.snapshot.paramMap.get('id');
  }

  getSuppliers() {
    this.service
      .getSupplierById(this.id)
      .pipe()
      .subscribe((supplier: any) => {
        if (supplier) {
          this.supplier = {
            id: supplier.id,
            name: supplier.razaoSocial,
            document: supplier.cnpj,
            activitySector: this.transformActivitySector(supplier.setorAtuacao),
            address: {
              zipcode: supplier.cep,
              province: supplier.uf,
              city: supplier.cidade,
              street: supplier.logradouro,
              number: supplier.numero, 
              neighborhood: supplier.bairro, 
              complement: supplier.complemento || 'Não informado',
            },
          };
        }
      });
  }

  transformActivitySector(value: string) {
    if(!value) return 'Não informado';
    return this.activitySectorOptions.find(
      (option) => option.value === value
    ).label;
  }

  formatCNPJ(value: string){
    if (value) {
      value = value.replace(/\D/g, '');

      if (value.length > 14) {
        value = value.substring(0, 14);
      }

      switch (value.length) {
        case 4:
          value = value.replace(/(\d{3})(\d+)/, '$1.$2');
          break;
        case 5:
          value = value.replace(/(\d{3})(\d+)/, '$1.$2');
          break;
        case 6:
          value = value.replace(/(\d{3})(\d+)/, '$1.$2');
          break;
        case 7:
          value = value.replace(/(\d{3})(\d{3})(\d+)/, '$1.$2.$3');
          break;
        case 8:
          value = value.replace(/(\d{3})(\d{3})(\d+)/, '$1.$2.$3');
          break;
        case 9:
          value = value.replace(/(\d{3})(\d{3})(\d+)/, '$1.$2.$3');
          break;
        case 10:
          value = value.replace(/(\d{3})(\d{3})(\d{3})(\d+)/, '$1.$2.$3-$4');
          break;
        case 11:
          value = value.replace(/(\d{3})(\d{3})(\d{3})(\d+)/, '$1.$2.$3-$4');
          break;
        case 12:
          value = value.replace(/(\d{2})(\d{3})(\d{3})(\d+)/, '$1.$2.$3/$4');
          break;
        case 13:
        case 14:
          value = value.replace(
            /(\d{2})(\d{3})(\d{3})(\d{4})(\d+)/,
            '$1.$2.$3/$4-$5'
          );
          break;
        default:
          return value;
      }
    }
    return value;
  }
}
