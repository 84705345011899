import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, first, map, of } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class SimplifiedOfferService {
  constructor(private readonly http: HttpClient) {}

  getSugestaoFrete(requestObj: any) {
    return this.http
      .post<any>(`${environment.API_BACK}/api/ia/inference`, requestObj)
      .pipe(
        map((dados) => {
          return dados;
        }),
        catchError((error) => {
          return of(error);
        })
      );
  }

  getGestaoUsuarioEmbarcador() {
    let params = new HttpParams();
    params = params.append('returnDTO', 'true');
    const httpOptions = {
      params: params,
    };

    return this.http.get<any>(`${environment.API_FILTER}/gestao-usuario`, httpOptions).pipe(
      first(),
      map((dados) => {
        return dados;
      })
    );
  }

  getTransportadoras(paramsObj: any) {
    let params = new HttpParams();
    params = params.append('size', String(paramsObj.size));
    params = params.append('page', String(paramsObj.page));
    if (paramsObj.certificacao) {
      params = params.append('certificacao', paramsObj.certificacao);
    }
    params = params.append('produto', paramsObj.produto);
    params = params.append('ufAtendida', paramsObj.ufsAtendidas);
    if (paramsObj.veiculo) {
      params = params.append('veiculo', paramsObj.veiculo);
    }
    if (paramsObj.possuiRastreador) {
      params = params.append('possuiRastreador', paramsObj.possuiRastreador);
    }

    if (
      paramsObj.nomeTransportadora != null &&
      paramsObj.nomeTransportadora != undefined
    ) {
      params = params.append('nome', paramsObj.nomeTransportadora);
    }
    const httpOptions = {
      params: params,
    };

    return this.http
      .get<any>(`${environment.API_FILTER}/empresa/parceiros`, httpOptions)
      .pipe(
        first(),
        map((dados) => {
          return dados;
        })
      );
  }

  criaOferta(obj: any) {
    return this.http.post<any>(`${environment.API_BACK}/oferta`, obj).pipe(
      map((dados) => {
        return dados;
      }),
      catchError((error) => {
        return of(error);
      })
    );
  }

  salvaAnexo(file: any) {
    const form: FormData = new FormData();
    form.append('file', file);
    const headers = new HttpHeaders();
    headers.append('responseType', 'text');
    headers.append('Content-Type', 'multipart/form-data');

    return this.http
      .post<any>(`${environment.API_BACK}/anexo/get-link`, form, {
        headers,
        responseType: 'text' as 'json',
      })
      .pipe(
        first(),
        map((dados) => {
          return dados;
        })
      );
  }

  getPedidos(ids: any[]) {
    let params = new HttpParams();
    ids.forEach((id: any) => {
      params = params.append('ids', String(id));
    });

    const httpOptions = {
      params: params,
    };

    return this.http
      .get<any>(
        `${environment.API_FILTER}/pedido/all/ids`,
        httpOptions
      )
      .pipe(
        first(),
        map((dados) => {
          return dados;
        })
      );
  }

  getRecipients(obj: any) {
    let params = new HttpParams();

    if(obj.document){
      params = params.append('documento', String(obj.document));
    }

    if(obj.name){
      params = params.append('nome', String(obj.name));
    }

    params = params.append('size', String(obj.size));
    params = params.append('page', String(obj.page));

    return this.http
      .get<any>(`${environment.API_FILTER}/destinatario`, { params: params })
      .pipe(
        first(),
        map((dados) => {
          return dados;
        })
      );
  }

  getSuppliers(obj: any) {
    let params = new HttpParams();

    if(obj.document){
      params = params.append('cnpj', String(obj.document));
    }

    if(obj.name){
      params = params.append('razaoSocial', String(obj.name));
    }

    params = params.append('size', String(obj.size));
    params = params.append('page', String(obj.page));

    return this.http
      .get<any>(`${environment.API_FILTER}/cdfornecedor`, { params: params })
      .pipe(
        first(),
        map((dados) => {
          return dados;
        })
      );
  }
}
