import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackbarComponent } from '../snackbar/snackbar.component';

@Injectable({
  providedIn: 'root',
})
export class SnackbarDefaultService {
  constructor(private _snackBar: MatSnackBar) {}

  openSnackBarSuccess(message: string) {
    this._snackBar.openFromComponent(SnackbarComponent, {
      duration: 5000,
      verticalPosition: 'top',
      horizontalPosition: 'right',
      data: {
        icon: 'assets/img/svg/success.svg',
        title: 'Sucesso',
        message: message,
        type: 'success',
      },
      panelClass: ['success'],
    });
  }

  openSnackBarError(message: string) {
    this._snackBar.openFromComponent(SnackbarComponent, {
      duration: 5000,
      verticalPosition: 'top',
      horizontalPosition: 'right',
      data: {
        icon: 'assets/img/svg/error.svg',
        title: 'Erro',
        message: message,
        type: 'error',
      },
      panelClass: ['error'],
    });
  }

  openSnackBarErrorWithDuration(message: string, duration: any) {
    this._snackBar.openFromComponent(SnackbarComponent, {
      duration: duration,
      verticalPosition: 'top',
      horizontalPosition: 'right',
      data: {
        icon: 'assets/img/svg/error.svg',
        title: 'Erro',
        message: message,
        type: 'error',
      },
      panelClass: ['error'],
    });
  }

  openSnackBarErrorWithDurationAndLog(message: string, duration: any, link: string) {
    this._snackBar.openFromComponent(SnackbarComponent, {
      duration: duration,
      verticalPosition: 'top',
      horizontalPosition: 'right',
      data: {
        icon: 'assets/img/svg/error.svg',
        title: 'Erro',
        message: message,
        type: 'error',
        link: link,
      },
      panelClass: ['error'],
    });
  }

  openSnackBarWarning(message: string, code?:string) {
    this._snackBar.openFromComponent(SnackbarComponent, {
      duration: 5000,
      verticalPosition: 'top',
      horizontalPosition: 'right',
      data: {
        icon: 'assets/img/svg/alert.svg',
        title: 'Aviso',
        message: message,
        type: 'warning',
        code: code
      },
      panelClass: ['warning'],
    });
  }

  openSnackBarWarningDuration(message: string, duration: any) {
    this._snackBar.openFromComponent(SnackbarComponent, {
      duration: duration,
      verticalPosition: 'top',
      horizontalPosition: 'right',
      data: {
        icon: 'assets/img/svg/alert.svg',
        title: 'Aviso',
        message: message,
        type: 'warning',
      },
      panelClass: ['warning'],
    });
  }

  openSnackBarInfo(message: string) {
    this._snackBar.openFromComponent(SnackbarComponent, {
      duration: 5000,
      verticalPosition: 'top',
      horizontalPosition: 'right',
      data: {
        icon: 'assets/img/svg/info.svg',
        title: 'Info',
        message: message,
        type: 'info',
      },
      panelClass: ['info'],
    });
  }

  openSnackBarInfoWithDuration(message: string, duration: any) {
    this._snackBar.openFromComponent(SnackbarComponent, {
      duration: duration,
      verticalPosition: 'top',
      horizontalPosition: 'right',
      data: {
        icon: 'assets/img/svg/info.svg',
        title: 'Info',
        message: message,
        type: 'info',
      },
      panelClass: ['info'],
    });
  }
}
